import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { useTypeSelector } from "hooks/useTypeSelector";
import "./UserHeaderPlayer.scss";

interface IUserHeaderPlayer {
 handleMockUnauth: () => void;
}

const UserHeaderPlayer = ({ handleMockUnauth }: IUserHeaderPlayer) => {
 const amount = useTypeSelector(
  (data) => data.accountInfoReducer.balance.balance.amount
 );
 const { pendingBonus } = useTypeSelector(
  (data) => data.accountInfoReducer.balance
 );
 const { active_bonus } = useTypeSelector(
  (data) => data.accountInfoReducer.balance
 );
 const { amount_bonus } = useTypeSelector(
  (data) => data.accountInfoReducer.balance.balance
 );
 const { role } = useTypeSelector((data) => data.accountInfoReducer);
 console.log(role)
 return (
  <>
   <div className="deposit-btn">
    <Link
     className="nav-menu-item"
     to={{
      search: "?profile=open&account=balance&page=deposit",
     }}
    >
     <span className="btn a-color">
      <i className="bc-i-circle-dollar" />
      <b className="ellipsis">{t("admin.Deposit")}</b>
     </span>
    </Link>
   </div>
   <div className="nav-menu-container header-user-nav">
    <ul className="nav-menu-other">
     <li>
      <Link
       className="nav-menu-item"
       to={{
        search: "?profile=open&account=balance&page=deposit",
       }}
      >
       <div className="hdr-user-info-content-bc">
        <span className="hdr-user-info-texts-bc ext-1 ellipsis">
         {(amount + amount_bonus).toFixed(2)} ₺
        </span>
       </div>
      </Link>
      <ul className="nav-menu-sub">
       <li>
        <Link
         className="nav-menu-item"
         to={{
          search: "?profile=open&account=balance&page=deposit",
         }}
        >
         <i className="bc-i-circle-dollar" />
         <span className="checkbox-control-text-bc ellipsis">
          {t("admin.Deposit")}
         </span>
        </Link>
       </li>
       <li>
        <Link
         className="nav-menu-item"
         to={{
          search: "?profile=open&account=balance&page=withdraw",
         }}
        >
         <i className="bc-i-withdraw" />
         <span className="checkbox-control-text-bc ellipsis">
          {t("admin.Withdraw")}
         </span>
        </Link>
       </li>
       <li>
        <Link
         className="nav-menu-item"
         to={{
          search: "?profile=open&account=balance&page=history",
         }}
        >
         <i className="bc-i-history" />
         <span className="checkbox-control-text-bc ellipsis">
          {t("admin.TransactionHistory")}
         </span>
        </Link>
       </li>
       <li>
        <Link
         className="nav-menu-item"
         to={{
          search: "?profile=open&account=balance&page=withdraws",
         }}
        >
         <i className="bc-i-withdraws" />
         <span className="checkbox-control-text-bc ellipsis">
          {t("admin.WithdrawStatus")}
         </span>
        </Link>
       </li>
       <li>
        <Link
         className="nav-menu-item"
         to={{
          search: "?profile=open&account=balance&page=buddy-transfer",
         }}
        >
         <i className="bc-i-buddy-transfer" />
         <span className="checkbox-control-text-bc ellipsis">
          {t("admin.BuddyTransfer")}
         </span>
        </Link>
       </li>
       {pendingBonus && (
        <li>
         <div className="nav-menu-item">
          <i className="bc-i" />
          <span className="checkbox-control-text-bc ellipsis">
           Pending Bonus: {pendingBonus.name}
          </span>
         </div>
        </li>
       )}
       {active_bonus && (
        <>
         <li>
          <div className="nav-menu-item">
           <i className="bc-i" />
           <span className="checkbox-control-text-bc ellipsis">
            Bonus money: {amount_bonus}
           </span>
          </div>
         </li>
         <li>
          <div className="nav-menu-item">
           <i className="bc-i" />
           <span className="checkbox-control-text-bc ellipsis">
            Active Bonus: {active_bonus.name}
           </span>
          </div>
         </li>
        </>
       )}
      </ul>
     </li>
    </ul>
    <ul className="nav-menu-other">
     <li>
      <Link
       className="nav-menu-item"
       to={{
        search: "?profile=open&account=profile&page=details",
       }}
      >
       <i
        className={`hdr-user-avatar-icon-bc bc-i-user ${
         role === "player" ? "user-verified" : "user-not-verified"
        } `}
       />
      </Link>
      <ul className="nav-menu-sub">
       <li>
        <Link
         className="nav-menu-item"
         to={{
          search: "?profile=open&account=balance&page=deposit",
         }}
        >
         <i className="bc-i-balance-management" />
         <span className="checkbox-control-text-bc ellipsis">
          {t("admin.BalanceManagement")}
         </span>
        </Link>
       </li>
       <li>
        <Link
         className="nav-menu-item"
         to={{
          search: "?profile=open&account=bet-builder-history&page=bets",
         }}
        >
         <i className="bc-i-bet-builder-history" />
         <span className="checkbox-control-text-bc ellipsis">
          {t("admin.BetBuilderhistory")}
         </span>
        </Link>
       </li>
       <li>
        <Link
         className="nav-menu-item"
         to={{
          search: "?profile=open&account=bonuses&page=loyalty-points",
         }}
        >
         <i className="bc-i-promotion" />
         <span className="checkbox-control-text-bc ellipsis">
          {t("admin.Bonuses")}
         </span>
        </Link>
       </li>
       <li>
        <Link
         className="nav-menu-item"
         to={{
          search: "?profile=open&account=profile&page=details",
         }}
        >
         <i className="bc-i-user" />
         <span className="checkbox-control-text-bc ellipsis">
          {t("admin.MyProfile")}
         </span>
        </Link>
       </li>
       <li>
        <Link
         className="nav-menu-item"
         to={{
          search: "?profile=open&account=history&page=bets",
         }}
        >
         <i className="bc-i-history" />
         <span className="checkbox-control-text-bc ellipsis">
          {t("admin.BetHistory")}
         </span>
        </Link>
       </li>
       <li>
        <Link
         className="nav-menu-item"
         to={{
          search: "?profile=open&account=messages&page=inbox",
         }}
        >
         <i className="bc-i-message" />
         <span className="checkbox-control-text-bc ellipsis">
          {t("admin.Messages")}
         </span>
        </Link>
       </li>
       <li className="logout-profile">
        <button
         className="btn  ellipsis "
         type="button"
         title="Logout"
         onClick={handleMockUnauth}
        >
         <span>{t("admin.Logout")}</span>
        </button>
       </li>
      </ul>
     </li>
    </ul>
   </div>
  </>
 );
};

export { UserHeaderPlayer };
