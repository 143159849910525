import React from "react";
import { useSearchParams } from "react-router-dom";
import { BuddyTransfer } from "../pages/BalanceManagement/BuddyTransfer/BuddyTransfer";
import { Deposit } from "../pages/BalanceManagement/Deposit/Deposit";
import { History } from "../pages/BalanceManagement/History/History";
import { Withdraw } from "../pages/BalanceManagement/Withdraw/Withdraw";
import { Withdraws } from "../pages/BalanceManagement/Withdraws/Withdraws";
import { BetNuildManegmentPage } from "../pages/BetBuilderManagement";
import { Bets } from "../pages/BetHistory/Bets/Bets";
import { ReceivedGifts } from "../pages/BetHistory/ReceivedGifts/ReceivedGifts";
import { BonusHistory } from "../pages/Bonuses/BonusHistory/BonusHistory";
import { CasinoBonus } from "../pages/Bonuses/CasinoBonus/CasinoBonus";
import { CasinoFreeSpins } from "../pages/Bonuses/CasinoFreeSpins/CasinoFreeSpins";
import { ExchangeShop } from "../pages/Bonuses/ExchangeShop/ExchangeShop";
import { LoyaltyPoints } from "../pages/Bonuses/LoyaltyPoints/LoyaltyPoints";
import { PromoCode } from "../pages/Bonuses/PromoCode/PromoCode";
import { SportBonus } from "../pages/Bonuses/SportBonus/SportBonus";
import { Inbox } from "../pages/Messages/Inbox/Inbox";
import { New } from "../pages/Messages/New/New";
import { Sent } from "../pages/Messages/Sent/Sent";
import { ChangePassword } from "../pages/MyProfile/ChangePassword/ChangePassword";
import { PersonalDetails } from "../pages/MyProfile/PersonalDetails/PersonalDetails";
import { TwoFactorAuthentication } from "../pages/MyProfile/TwoFactorAuthentication/TwoFactorAuthentication";
import { VerifyAccount } from "../pages/MyProfile/VerifyAccount/VerifyAccount";
import "./InfoBlock.scss";

const InfoBlock = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "";
  const account = searchParams.get("account") || "";
  return (
    <div className="my-profile-info-block">
      {page === "deposit" ? <Deposit /> : null}
      {page === "withdraw" ? <Withdraw /> : null}
      {page === "history" ? <History /> : null}
      {page === "withdraws" ? <Withdraws /> : null}
      {page === "buddy-transfer" ? <BuddyTransfer /> : null}
      {(page === "bets" && account === "bet-builder-history") ||
      (page === "open-bets" && account === "bet-builder-history") ||
      page === "won" ||
      page === "lost" ? (
        <BetNuildManegmentPage />
      ) : null}
      {page === "exchange-shop" ? <ExchangeShop /> : null}
      {page === "sport" ? <SportBonus /> : null}
      {page === "bonus-history" ? <BonusHistory /> : null}
      {page === "casino" ? <CasinoBonus /> : null}  
      {page === "promo-code" ? <PromoCode /> : null}
      {page === "casino-free-spins" ? <CasinoFreeSpins /> : null}
      {page === "details" ? <PersonalDetails /> : null}
      {page === "change-password" ? <ChangePassword /> : null}
      {page === "two-factor-authentication" ? (
        <TwoFactorAuthentication />
      ) : null}
      {(page === "bets" && account === "history") ||
      (page === "open-bets" && account === "history") ||
      (page === "gifted" && account === "history") ? (
        <Bets />
      ) : null}
      {page === "gifts" ? <ReceivedGifts /> : null}
      {page === "inbox" ? <Inbox /> : null}
      {page === "sent" ? <Sent /> : null}
      {page === "new" ? <New /> : null}
    </div>
  );
};

export { InfoBlock };
