import React, { useState } from "react";
import { GameProvider } from "common/types/Casino/GameProvider";
import { AccordionProviders } from "./components/AccordionProviders/AccordionProviders";
import { SelectGames } from "./components/SelectGames/SelectGames";
import { SelectProviders } from "./components/SelectProviders/SelectProviders";
import "./SelectorProvider.scss";

interface ISelectorProvider {
  providersList: object | GameProvider;
  selectedProviders: number[];
  setSelectedProviders: React.Dispatch<React.SetStateAction<number[]>>;
  seacrhGames: string;
  setSeacrhGames: React.Dispatch<React.SetStateAction<string>>;
}

const SelectorProvider = ({
  providersList,
  selectedProviders,
  setSelectedProviders,
  seacrhGames,
  setSeacrhGames,
}: ISelectorProvider) => {
  const [filteredData, setFilteredData] = useState(providersList);
  const [searchInput, setSearchInput] = useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setSearchInput(input);

    if (input === "") {
      setFilteredData(providersList); // Если input пустой, показываем все данные
    } else {
      filterData(input);
      setSelectedProviders([]);
    }
  };

  const filterData = (searchQuery: string) => {
    const filtered = Object.keys(providersList)
      .filter((provider) =>
        provider.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .reduce((filteredObj, key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        filteredObj[key] = providersList[key];
        return filteredObj;
      }, {});
    setFilteredData(filtered);
  };
  return (
    <div className="casino-select-provider-button-block-bc">
      <SelectProviders
        handleSearchChange={handleSearchChange}
        searchInput={searchInput}
      />
      <AccordionProviders
        providersList={providersList}
        selectedProviders={selectedProviders}
        setSelectedProviders={setSelectedProviders}
        filteredData={filteredData}
        searchInput={searchInput}
      />
      <SelectGames setSeacrhGames={setSeacrhGames} seacrhGames={seacrhGames} />
    </div>
  );
};

export { SelectorProvider };
