enum statusEnum {
  cancaled = "cancaled",
  completed = "completed",
  inprogress = "in progress",
  canceled = "canceled", 
  failed = "failed"
}

type transactionsType = {
  id: number;
  type: string;
  status: statusEnum;
  extra_data: string[];
  payment_method: string;
  amount: number;
  amount_crypto: string;
  currency: string;
  created_at: string;
  completed_at: null | string;
  failed_at: null | string;
};

export type { transactionsType };
export { statusEnum };
