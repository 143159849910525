import React, { useEffect } from "react";
import { CasinoIconGameType } from "common/types/Casino/CasinoIconGameType";
import { CasinoInfo } from "services";

interface IuseJackpotGames {
  page: React.MutableRefObject<number>;
  seacrhGames: string;
  loadStop: boolean;
  setLoadingGames: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingGamesAdd: React.Dispatch<React.SetStateAction<boolean>>;
  setCasinoGamesList: React.Dispatch<React.SetStateAction<CasinoIconGameType[] | []>>;
  setLoadStop: React.Dispatch<React.SetStateAction<boolean>>
}

const useJackpotGames = ({
  page,
  seacrhGames,
  loadStop,
  setLoadingGames,
  setLoadingGamesAdd,
  setCasinoGamesList,
  setLoadStop,
}: IuseJackpotGames) => {
  useEffect(() => {
    setLoadingGames(true);
    page.current = 1;
    async function casinoGames() {
      setLoadingGamesAdd(true);
      page.current++;
      const res = await CasinoInfo.games(
        `/13?current_page=1&limit=25&page=${page.current}&is_mobile=true`
      );
      setCasinoGamesList((prevState) =>
        prevState.concat(res.data.data)
      );
      setLoadingGamesAdd(false);
    }
    async function casinoGamesSearch() {
      const res = await CasinoInfo.searchGames(
        `?current_page=${page}&limit=25&page=1&search=${seacrhGames}&is_mobile=true`
      );
      setCasinoGamesList(res.data.data);
      setLoadingGames(false);
    }
    if (seacrhGames.length > 0) {
      casinoGamesSearch();
    } else {
      casinoGames();
    }
  }, [seacrhGames]);

  //search casino slots
  const loadMore = () => {
    async function casinoGames() {
      setLoadingGamesAdd(true);
      page.current++;
      const res = await CasinoInfo.games(
        `/13?current_page=1&limit=25&page=${page.current}&is_mobile=true`
      );
      setCasinoGamesList((prevState) =>
        prevState.concat(res.data.data)
      );
      setLoadingGamesAdd(false);
      if (res.data.data.length === 0) {
        setLoadStop(true)
      } else {
        setLoadStop(false)
      }
    }
    async function casinoGamesSearch() {
      setLoadingGamesAdd(true);
      page.current++;
      const res = await CasinoInfo.searchGames(
        `?current_page=1&limit=25&page=${page.current}&search=${seacrhGames}&is_mobile=true`
      );
      setCasinoGamesList((prevState) =>
        prevState.concat(res.data.data)
      );
      setLoadingGamesAdd(false);
      if (res.data.data.length === 0) {
        setLoadStop(true)
      } else {
        setLoadStop(false)
      }
    }
    if (seacrhGames.length > 0) {
      if (!loadStop) {
        casinoGamesSearch();
      }
    } else {
      if (!loadStop) {
        casinoGames();
      }      
    }
  };

  useEffect(() => {
    if (seacrhGames.length === 0) {
      loadMore()
      setLoadStop(false)
    }
  }, [seacrhGames])

  window.onscroll = function() {
    if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
     loadMore()
    }
   }
};

export { useJackpotGames };
