import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import i18n from "i18n";
import { CasinoInfo } from "services";
import { useTypeSelector } from "hooks/useTypeSelector";
import { setLanguage } from "store/reducers/settingsReducer";
import { setBannersList } from "store/reducers/accountInfoReducer";
interface RouteWrapperProps {
  element: React.ReactNode;
}

const RouteWrapper = ({ element: Element, ...rest }: RouteWrapperProps) => {
  const { en } = useParams();
  const local = useLocation();
  const dispatch = useDispatch();
  const banners = useTypeSelector((data) => data.accountInfoReducer.banners);
  useEffect(() => {
    if (en === "en" || en === "tr" || en === "de") {
      i18n.changeLanguage(en);
      if (en === "en") {
        dispatch(
          setLanguage({
            languageShort: "en",
            languageAbs: "ENG",
            languageFlag: "unitedkingdom",
            languageName: "English",
          })
        );
      } else if (en === "tr") {
        dispatch(
          setLanguage({
            languageShort: "tr",
            languageAbs: "TRY",
            languageFlag: "turkey",
            languageName: "Türkçe",
          })
        );
      }
    }
    async function bannerFun() {
      const bannerOut = await CasinoInfo.MainBanners(en ? en : "en");
      dispatch(setBannersList(bannerOut.data));
    }
    banners.length === 0 && bannerFun();
  }, [dispatch, en, local]);

  return <>{Element}</>;
};

export { RouteWrapper };
