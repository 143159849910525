import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CasinoGameType } from "common/types/Casino/CasinoGameType";
import { FavoritCasinoResponceType } from "common/types/Casino/FavoritCasinoResponceType";
import { FavoritCasinoStateType } from "common/types/Casino/FavoritCasinoStateType";
import { usePlayerRightWindow } from "hooks/usePlayerRightWindow";
import { useTypeSelector } from "hooks/useTypeSelector";

import "./RightWindowPlay.scss";

interface IRightWindowPlay {
  gamesSelected: CasinoGameType[];
  openGames: string[];
  favoritsGamesUser: FavoritCasinoResponceType[] | [];
  favoritsGamesLocal: FavoritCasinoStateType[] | [];
  casinoLink: string[];
  windowWidth: number;
  openGamesUrl: string;
  gameNamesUrl: string;
  openWindows: number;
  setGamesSelected: React.Dispatch<React.SetStateAction<CasinoGameType[]>>;
  seGameSelectOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RightWindowPlay = ({
  gamesSelected,
  favoritsGamesUser,
  favoritsGamesLocal,
  openGames,
  windowWidth,
  openGamesUrl,
  gameNamesUrl,
  setGamesSelected,
  seGameSelectOpen,
}: IRightWindowPlay) => {
  const [iframeKey, setIframeKey] = useState(1);
  const { handleFavoriteUserSelect, handleFavoriteLocalSelect } =
    usePlayerRightWindow({
      gamesSelected: gamesSelected,
      openGames: openGames,
      favoritsGamesUser: favoritsGamesUser,
      favoritsGamesLocal: favoritsGamesLocal,
      openGamesUrl: openGamesUrl,
      gameNamesUrl: gameNamesUrl,
    });

  const role = useTypeSelector((data) => data.accountInfoReducer.role);
  const navigate = useNavigate();

  const handleRefreshIframe = () => {
    setIframeKey((prev) => prev + 1);
  };

  const handleOpenSelect = () => {
    seGameSelectOpen(true);
  };

  const handleUpdateParams = () => {
    const updatedOpenGames = openGamesUrl ? openGamesUrl.split(",")[0] : "";
    const updatedGameNames = gameNamesUrl ? gameNamesUrl.split(",")[0] : "";

    const newSearchParams = new URLSearchParams();
    newSearchParams.set("openGames", updatedOpenGames);
    newSearchParams.set("gameNames", updatedGameNames);

    const newUrl = "?" + newSearchParams.toString();

    navigate(newUrl);
    setGamesSelected([gamesSelected[0]]);
  };
  return (
    <div
      className="casino-full-game-block"
      style={{ width: (windowWidth - 120) / 2, height: windowWidth / 3 / 2 }}
    >
      {gamesSelected[1] ? (
        <>
          <div className="casino-full-game-panel">
            <i
              title="Close"
              className="casino-full-game-panel-element bc-i-close-remove"
              onClick={handleUpdateParams}
            />
            <i
              title="Open in popup window"
              className="casino-full-game-panel-element bc-i-multiview"
            />
            <i
              title="Refresh"
              className="casino-full-game-panel-element bc-i-refresh"
              onClick={handleRefreshIframe}
            />
            {role === "player" ? (
              <i
                title="FAVORİ OYUNLARA EKLE"
                className={`casino-full-game-panel-element ${
                  favoritsGamesUser.some(
                    (obj) => obj?.id === gamesSelected[0]?.id
                  )
                    ? "bc-i-star"
                    : "bc-i-favorite"
                } `}
                onClick={handleFavoriteUserSelect}
              />
            ) : (
              <i
                title="FAVORİ OYUNLARA EKLE"
                className={`casino-full-game-panel-element ${
                  favoritsGamesLocal.some(
                    (obj) => obj?.id === gamesSelected[0]?.id
                  )
                    ? "bc-i-star"
                    : "bc-i-favorite"
                } `}
                onClick={handleFavoriteLocalSelect}
              />
            )}
            <span
              title="Click to change to real mode"
              className="casino-full-game-panel-element casino-full-game-switcher "
            />
          </div>
          <iframe
            allowFullScreen={true}
            allow="autoplay; microphone *"
            src={gamesSelected[1]?.game_link}
            key={iframeKey}
          />
        </>
      ) : (
        <i
          className="casino-full-game-add-icon bc-i-add custom-plus-icon"
          onClick={handleOpenSelect}
        />
      )}
    </div>
  );
};

export { RightWindowPlay };
