import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { UseFormRegister, FieldValues, UseFormWatch } from "react-hook-form";
import { userFormType } from "../../../ModalRegister";
import "./InputPassport.scss";

interface IInputPassport {
  register: UseFormRegister<userFormType>;
  watch: UseFormWatch<userFormType>;
  errorsRequest: any;
}

const InputPassport = ({ register, watch, errorsRequest }: IInputPassport) => {
  const [inputValue, setInputValue] = useState("");
  const [inputFocus, setInputFocus] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [shortError, setShortError] = useState(false);
  const [longError, setLongError] = useState(false);
  const name = watch("passport");

  useEffect(() => {
    setInputValue(name);
    setFieldError(false);
    setShortError(false);
    setLongError(false);
  }, [name]);

  const handleOnFocus = () => {
    setInputFocus(true);
  };

  const handleOnBlur = () => {
    if (inputValue.length === 0) {
      setInputFocus(false);
      setFieldError(true);
    }
    if (inputValue.length < 2 && inputValue.length > 0) {
      setShortError(true);
    }
    if (inputValue.length > 20) {
      setLongError(true);
    }
  };
  return (
    <div className="entrance-f-item-bc">
      <div
        className={`form-control-bc default ${
          fieldError || shortError || longError ? "invalid" : ""
        } ${inputFocus ? "focused" : ""}`}
      >
        <label className="form-control-label-bc inputs">
          <input
            type="text"
            className="form-control-input-bc"
            {...register("passport", {
              required: true,
            })}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
            {t("admin.PassportID")}
          </span>
        </label>
        {fieldError || shortError || longError || errorsRequest?.passport ? (
          <div className="form-control-message-holder-bc">
            <span className="form-control-message-bc">
              {fieldError ? t("admin.Thisfieldisrequired") : ""}
              {shortError ? t("admin.Tooshort") : ""}
              {longError ? t("admin.Toolong") : ""}
              {errorsRequest?.passport ? errorsRequest?.passport[0] : ""}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { InputPassport };
