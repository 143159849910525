import React from "react";
import "./TwoFactorAuthentication.scss";
import authentication from "accets/images/png/profile/authentication.png";
import { t } from "i18next";

const TwoFactorAuthentication = () => {
  return (
    <>
      <div className="overlay-header">{t("admin.TwoStepAuthentication")}</div>
      <div className="authentication-section">
        <img
          src={authentication}
          alt="authentication"
          className="authentication-image"
        />
        <div className="toggle-wrapper  ">
          <span className="toggle-title ellipsis">
            {t("admin.Activatetwofactorauthentication")}
          </span>
          <i className="ui-kit-toggle active" />
        </div>
      </div>
    </>
  );
};

export { TwoFactorAuthentication };
