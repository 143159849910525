import React from "react";
import { Link } from "react-router-dom";
import "./Logo.scss";

import logo from "accets/images/png/logo.png";
import logoVeri from "accets/images/jpeg/logoVeri.jpeg";
import { useTypeSelector } from "hooks/useTypeSelector";

const Logo = () => {
 const language = useTypeSelector(
  (data) => data.settingsReducer.language.languageShort
 );
 return (
  <div className="logo-container">
   <Link className="logo" to={`/${language}/`}>
    <img className="hdr-logo-bc" src={logo} alt="" />
   </Link>
   <a
    className=" header-icon"
    target="_blank"
    href="https://licence-curacao-egaming.com/"
    rel="noreferrer"
   >
    <img src={logoVeri} alt="" style={{ position: "relative", top: "-3px" }} />
   </a>
  </div>
 );
};

export { Logo };
