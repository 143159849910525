import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import "./ModalRegister.scss";
import { ModalRegisterForm } from "./modalRegisterForm/ModalRegisterForm";
import logo from "accets/images/png/logo.png";
import { useTypeSelector } from "hooks/useTypeSelector";
import { AdminPanel, AuthService } from "services";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import {
 setAuth,
 setUserInfo,
 setUserBalanceInfo,
} from "store/reducers/accountInfoReducer";
import { setModalRegister, setModalLogin } from "store/reducers/modalsReducer";
import registerPromo from "accets/images/png/registerImage/registerPromo.jpeg";

type userFormType = {
 login: string;
 address: string;
 agree: boolean;
 city: string;
 email: string;
 firstname: string;
 lastname: string;
 passport: string;
 password: string;
 password_confirmation: string;
 phone: string;
 promocode: string;
 username: string;
};

const ModalRegister = () => {
 const defaultBirthday = new Date();
 defaultBirthday.setFullYear(defaultBirthday.getFullYear() - 18);

 const [birthdayData, setBirthdayData] = useState<Date | null>(defaultBirthday);
 const [errorsRequest, setrrorsRequest] = useState<any>();
 const [selectedGender, setSelectedGender] = useState(1);
 const [selectedCountryShort, setSelectedCountryShort] = useState("TR");
 const [countryCode, setCountryCode] = useState(90);
 const [currenctSelect, setCurrenctSelect] = useState("TRY");
 const dispatch = useDispatch();
 const language = useTypeSelector(
  (data) => data.settingsReducer.language.languageShort
 );
 const {
  register,
  handleSubmit,
  watch,
  formState: { errors },
 } = useForm<userFormType>();
 const handleMoveToAuth = () => {
  dispatch(setModalRegister(false));
  dispatch(setModalLogin(true));
 };
 const handleCloseWindow = () => {
  dispatch(setModalRegister(false));
 };
 const birthdayDataString = birthdayData?.toString();
 const onSubmit = (data: userFormType) => {
  async function autotizationLogin() {
   if (birthdayDataString) {
    const dateObj = new Date(birthdayDataString);

    const year = dateObj.getFullYear();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);

    const desiredFormat = `${year}-${month}-${day}`;
    try {
     const res = await AuthService.register({
      address: data.address,
      affid: null,
      birthday: desiredFormat,
      country: selectedCountryShort,
      currency: currenctSelect,
      cxd: null,
      email: data.email,
      firstname: data.firstname,
      gender: selectedGender,
      language: language,
      lastname: data.lastname,
      passport: data.passport,
      password: data.password,
      password_confirmation: data.password_confirmation,
      phone: countryCode + data.phone,
      promocode: data.promocode,
      state: "AF2312",
      username: data.username,
      zipcode: "603163",
     });
     if (res.status === 200) {
      dispatch(setAuth("player"));
      dispatch(setModalRegister(false));
      localStorage.setItem("token", res.data);
      const data = await AdminPanel.userInfo();
      dispatch(setUserInfo(data.data));
      const resdata = await AdminPanel.userBalance();
      dispatch(setUserBalanceInfo(resdata.data));
     }
    } catch (e) {
     // setInvalidData(true);
     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
     //@ts-ignore
     setrrorsRequest(e.response.data.errors);
    }
   }
  }
  autotizationLogin();
 };

 return (
  <div className="entrance-popup-bc register">
   <div className="e-p-content-holder-bc">
    <div className="e-p-content-bc">
     <div className="e-p-header-bc">
      <Link
       className="popup-t-logo-w-bc"
       to={`/${language}/`}
       onClick={handleCloseWindow}
      >
       <img className="hdr-logo-bc" src={logo} alt="" />
      </Link>
      <div className="e-p-sections-bc">
       <div className="e-p-section-item-bc" onClick={handleMoveToAuth}>
        <span className="e-p-section-title-bc">{t("admin.Signin")}</span>
       </div>
      </div>
     </div>
     <div className="e-p-body-bc">
      <div className="reg-form-block-bc">
       <form
        className="entrance-form-bc registration popup"
        onSubmit={handleSubmit(onSubmit)}
       >
        <div
         className="entrance-form-content-bc single-side step-0"
         data-scroll-lock-scrollable=""
        >
         <div className="slider-bc  ">
          <div className="carousel">
           <div className="carousel-viewport">
            <div
             className="carousel-container"
             style={{ transform: "translate3d(0%, 0px, 0px)" }}
            >
             <div
              className="carousel-slide active-slide"
              style={{ width: "100%" }}
             >
              <div className="sdr-item-holder-bc">
               <Link
                target="_self"
                className="sdr-item-bc"
                to={`/${language}/promotions`}
                onClick={handleCloseWindow}
               >
                <img
                 src={registerPromo}
                 loading="lazy"
                 className="sdr-image-bc"
                 alt=""
                 title="Registration"
                />
               </Link>
              </div>
             </div>
            </div>
           </div>
          </div>
         </div>
         <div className="reg-form-content" data-scroll-lock-scrollable="">
          <div className="sg-n-text-row-1-bc">New member?</div>
          <div className="sg-n-text-row-2-bc">
           {t("admin.Registernowitseasy")}
          </div>
          <div className="reg-form-fields">
           <ModalRegisterForm
            register={register}
            watch={watch}
            birthdayData={birthdayData}
            setBirthdayData={setBirthdayData}
            selectedGender={selectedGender}
            setSelectedGender={setSelectedGender}
            selectedCountryShort={selectedCountryShort}
            setSelectedCountryShort={setSelectedCountryShort}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            currenctSelect={currenctSelect}
            setCurrenctSelect={setCurrenctSelect}
            errors={errors}
            errorsRequest={errorsRequest}
           />
          </div>
          <div className="entrance-f-item-bc entrance-f-recaptcha-bc">
           <div className="u-i-p-control-item-holder-bc ">
            <img
             className="recaptcha-img"
             src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAABL1BMVEUAAAD///////////////////////////////////////////////+rq6tChfQcOqkkQa0hP6xUkfatra1OjfX19fU+fezX5v3i5vS1tbU3UrSwsLCmqbCvr6/t7e2xsbH8/P1XkvYuSrGkqLDi4uK4ubn4+v1Bg/Lx8fHW1tbIyMjDw8O9vb20tLSnqq7m7/7m6fZgmfZHiPS5zPPv7+9Cge/M0uzH0Ove3t5bccE+WLezs7MnRK7y9//C2PyryfuZvvpuovdEhPDs7Ozo6Ojk5OSwu+G7yNygrduMm9TM3v2yzvv19viEsPh5qfhZlPZkmfOvxPCqwfCRsvB2n+3f5OzK1+vY3um/z+h7nua6w+Wst+Crtt/Z2dmTodaPntXByNTR0dFsf8hNZLxHYLreSXCvAAAADHRSTlMA/fPy2baba04cGm5J2ZqkAAACaElEQVRYw63YaVfaQBSA4bALjgFssLUkhEZAWVxQAQGX1rp23/e9/v/f4BgTZ5IL3EHv+80RnjPnDJdDRvNKpxLxKJuyaDyRSmtymWSE3bJIMiOcmRi7Q7EZ35lFtoNuatbbD+bgkrunTIzduViGQ0lGUJKfe4QCiqS1FCMppSVooIQWp4HiWpQGimqhhfX2vJyxedjpLDiD//9+fatNlMIQu2/o4Raybrn+9xoKQQlCPOf8BQ5BCUKcOhMv3AAQkCAk6vvvfzDAIPYeQHJO99p5mMegt8sQktvtuk4Wg7Y8B0BC6l05AIIOBmVzP7iDQOuHOg7xUOiJTgMd6DTQnjECMk7O+86U0GN9RI/4P8onF9NAtdCGPhg+xDvLq0PPA0znHZ8WAbGuowLBI1vecudOQKyXV4T2NmXoqzfBAmK/FaF92Xlz813gQ3wucAie2T7zpTZwMOiV5LyWpgY4GNSWoJdiGTgoNC9BB9BRh+RDexZ0NgY50O5YyDTNJXPpKtO0ijzLqhYYGoTW5sItAkcJwhx1CDoEEOIgEOasqEH3AtAfBtqxnqpDos9gP9YckFQg8yMLVBryxWJJHRKdMqlji68ICYfkqhX/1/N2g/8pJAyC2c165Xjnb0teKuGQWvY2BSQkHMJr4dDi6RruDFdQiM/FF1SygQMgd756iFTET82b00/mJGcV/xzdfK8WmuOdZo2hUEGac3vMdirI0ILKdXsEUy8zFIJUpRn4YFkNb/BwCFpH9UarOlyttho/j8psUmQPfmSPomQPx2SP62QXCFRXGmSXLFTXPlQXUVRXY6SXdWTXh5dIMReAck/CkAAAAABJRU5ErkJggg=="
             alt=""
            />
            <div className="form-control-title-static-bc">
             <Link
              to="https://policies.google.com/privacy"
              rel="noopener noreferrer"
              target="_blank"
              onClick={handleCloseWindow}
             >
              {t("admin.PrivacyPolicy")}
             </Link>{" "}
             {t("admin.and")}{" "}
             <Link
              to="https://policies.google.com/terms"
              rel="noopener noreferrer"
              target="_blank"
             >
              {t("admin.TermsService")}
             </Link>{" "}
            </div>
           </div>
          </div>
         </div>
        </div>
        <div className="reg-form-footer-bc">
         <div className="entrance-form-actions-holder-bc reg-ext-1">
          <button className="btn a-color " type="submit" title="Register">
           <span>{t("admin.Register")}</span>
          </button>
         </div>
         <div className="steps-indicator">
          <div className="step-indicator step-indicator-active" />
         </div>
         <div title="Contact support" className="live-chat-adviser-bc">
          <i className="bc-i-live-chat" />
          <span className="ellipsis">{t("admin.Contactsupport")}</span>
         </div>
        </div>
       </form>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export { ModalRegister };

export type { userFormType };
