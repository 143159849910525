import React, { useEffect, useState } from "react";
import { useTypeSelector } from "hooks/useTypeSelector";
import "./Time.scss";

const Time = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const timeFormat = useTypeSelector((data) => data.settingsReducer.timeFormat);
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );

  const hours = time.getHours().toString().padStart(2, "0");
  const minutes = time.getMinutes().toString().padStart(2, "0");
  const seconds = time.getSeconds().toString().padStart(2, "0");

  const formattedTime = time.toLocaleString(language === "tr" ? "tr-TR" : "en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  return (
    <>
      {timeFormat === "24h" ? (
        <span className="hdr-time-bc">{`${hours}:${minutes}:${seconds}`}</span>
      ) : (
        <span className="hdr-time-bc">{formattedTime}</span>
      )}
    </>
  );
};

export { Time };
