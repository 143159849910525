import React, { ReactNode, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTypeSelector } from "hooks/useTypeSelector";
import { BetsLipContainer } from "./betsLipContainer/BetsLipContainer";
import { Footer } from "./footer/Footer";
import { Header } from "./header/Header";
import { PopupManagerPlayer } from "./popup/playerPopup/PopupManagerPlayer";
import { PopupManager } from "./popup/unauthPopup/PopupManager";
import "./index.scss";
import { CasinoPlayer } from "../CasinoPlayer/CasinoPlayer";
import { CasinoSelectPopap } from "../CasinoSelectPopap/CasinoSelectPopap";
import { CompanyInfoModal } from "./footer/FooterModal/CompanyInfoModal/CompanyInfoModal";
import { FreeSpin } from "./freeSpin/FreeSpin";
import axios from "axios";
import { PageType } from "common/types/PageType/PageType";
import { CasinoInfo } from "services";

interface ILayout {
  addClasses: string;
  children: ReactNode;
  panelOnep: boolean;
  setPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navStatus: string;
  betsLipPage: string;
  betsLipOpen?: boolean;
  setBetsLipOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Layout = ({
  addClasses,
  children,
  panelOnep,
  setPanelOpen,
  navStatus,
  betsLipPage,
  betsLipOpen,
  setBetsLipOpen,
}: ILayout) => {
  const { balance } = useTypeSelector((data) => data.accountInfoReducer);

  const [isFooterContent, setIsFooterContent] = useState(false);
  const [footerPageInfo, setFooterPageInfo] = useState<PageType | null>(null);
  const [gameSelectOpen, setGameSelectOpen] = useState(false);
  const { role } = useTypeSelector((data) => data.accountInfoReducer);
  const [searchParams] = useSearchParams();
  const helpPageContent = searchParams.get("helpPageContent") || "";
  const profile = searchParams.get("profile") || "";
  const openGames = searchParams.get("openGames") || "";
  const gameNames = searchParams.get("gameNames") || "";

  async function getFooterPageInfo() {
    try {
      const res = await CasinoInfo.page(helpPageContent);
      setFooterPageInfo(res.data);
      setIsFooterContent(true);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    helpPageContent && getFooterPageInfo();
  }, [helpPageContent]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const params = {
        games: ["bsg:CharmsAndClovers"],
        providers: ["181"],
        categories: ["6"],
      };
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get("https://casino-api.services/user-dashboard/freespin_info", {
          params: params,
          headers: headers,
        })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  return (
    <div
      className={`${addClasses} layout-bc theme-default ${
        openGames && gameNames ? "casino-lock-scroll" : ""
      } ${betsLipOpen ? "betslip-Full" : "betslip-Hidden"}`}
    >
      {balance.active_bonus ? <FreeSpin /> : null}
      {/* <CasinoInfo /> */}
      <Header
        panelOnep={panelOnep}
        setPanelOpen={setPanelOpen}
        navStatus={navStatus}
        betsLipOpen={betsLipOpen}
        setBetsLipOpen={setBetsLipOpen}
      />
      <div className="layout-content-holder-bc">
        {openGames && gameNames ? (
          <CasinoPlayer seGameSelectOpen={setGameSelectOpen} />
        ) : null}
        {children}
        {gameSelectOpen ? (
          <CasinoSelectPopap setGameSelectOpen={setGameSelectOpen} />
        ) : null}
        {isFooterContent && footerPageInfo ? (
          <CompanyInfoModal
            footerPageInfo={footerPageInfo}
            setIsFooterContent={setIsFooterContent}
            setFooterPageInfo={setFooterPageInfo}
          />
        ) : null}
      </div>
      <Footer />
      <PopupManager role={role} />
      {role === "player" ? (
        <>{profile === "open" ? <PopupManagerPlayer /> : null}</>
      ) : null}
      <BetsLipContainer
        betsLipPage={betsLipPage}
        betsLipOpen={betsLipOpen}
        setBetsLipOpen={setBetsLipOpen}
      />
    </div>
  );
};

export { Layout };
