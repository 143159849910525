import { userDeposit } from "common/types/AdminInfo/userDeposit";
import { AdminPanel } from "services";

type getPaymentUrlType = {
  inputValue: string;
  selectedPaymentMethod?: userDeposit;
  setPassportUnvalid: React.Dispatch<React.SetStateAction<boolean>>;
};

async function getPaymentUrl({
  inputValue,
  selectedPaymentMethod,
  setPassportUnvalid,
}: getPaymentUrlType) {
  try {
    const res = await AdminPanel.userPay({
      data: {
        amount: Number(inputValue) * 100,
        currency: "TRY",
        payment_aggregator_id: selectedPaymentMethod?.payment_aggregator_id,
        service_id: Number(selectedPaymentMethod?.service_id) || 0,
      },
      params: "",
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (res.data === "validation.passportError") {
      setPassportUnvalid(true);
    } else if (res.data.status === "FAIL") {
      alert(res.data.message);
    } else {
      window.open(res.data.redirectUrl, "_blank");
    }
  } catch (e) {
    alert(e);
  }
}

export { getPaymentUrl };
