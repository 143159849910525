import axios from "axios";
const url = process.env.REACT_APP_API_ORIGIN_URL;

const appToken = process.env.REACT_APP_API_ORIGIN_TOKEN;

const apiUnauth = axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json",
  },
});

apiUnauth.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${appToken}`;

  return config;
});

export { apiUnauth };
