import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTypeSelector } from "hooks/useTypeSelector";
import "./PromotionSlots.scss";
import { BananersEnum, BannersList } from "common/types/Banners/BannersList";
import { CasinoInfo } from "services";
import { promotionsResponceType } from "common/types/Promotions/promotionsResponceType";
import parse from "html-react-parser";

const PromotionSlot = () => {
  const [contentImage, setContentImage] = useState("");
  const [contentResponce, setContentResponce] =
    useState<promotionsResponceType | null>(null);

  const navigate = useNavigate();

  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );

  const handleCloseInfo = () => {
    setContentImage("");
    setContentResponce(null);
    navigate(`/${language}/promotions/all`);
  };

  const handleOpenInfo = async (item: BannersList) => {
    try {
      const res = await CasinoInfo.promotions(item.url || "");
      setContentResponce(res.data);
      setContentImage(item.desktop_image);
      navigate(`/${language}/promotions/all/${item.url}`);
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpenInfoDefault = async (item: string) => {
    try {
      const res = await CasinoInfo.promotions(item);
      setContentResponce(res.data);
      setContentImage(res.data.list_image);
      navigate(`/${language}/promotions/all/${item}`);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (id) {
      handleOpenInfoDefault(id);
    }
  }, []);

  const bannersList = useTypeSelector(
    (data) => data.accountInfoReducer.banners
  );

  const { id } = useParams();

  const wrapperRef = useRef(null);
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      handleCloseInfo();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  return (
    <section className="promotionsList-bc">
      {bannersList
        .filter((item) => item.type === BananersEnum.Promotions)
        .map((item) => {
          return (
            <article
              className="promotionsListEl-bc"
              key={item.id}
              onClick={() => handleOpenInfo(item)}
            >
              <img
                className="promotionsArticleThumbnail-bc"
                alt=""
                title={item.title}
                src={item.desktop_image}
              />
              <h3 className="promotions-article-title ellipsis">
                {item.title}
              </h3>
            </article>
          );
        })}
      {contentResponce && id ? (
        <div className="popup-holder-bc windowed  promotion-details-popup ">
          <div className="popup-middleware-bc">
            <div className="popup-inner-bc" ref={wrapperRef}>
              <>
                <i
                  className="e-p-close-icon-bc bc-i-close-remove"
                  onClick={handleCloseInfo}
                />
                <div className="overlay-header">{contentResponce.title}</div>
                <div className="promotion-details-container">
                  <div className="promotion-details-header">
                    <img
                      src={contentImage}
                      className="promotion-details-img"
                      alt=""
                      title="Her Yatırıma Aviator Freebet"
                    />
                  </div>{" "}
                  <div
                    className="promotion-details-info"
                    data-scroll-lock-scrollable=""
                  >
                    {contentResponce.rules
                      ? parse(contentResponce.rules)
                      : null}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
};

export { PromotionSlot };
