import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FavoritCasinoResponceType } from '../../common/types/Casino/FavoritCasinoResponceType';
import { FavoritCasinoStateType } from '../../common/types/Casino/FavoritCasinoStateType';

interface FavoritsState {
  favoritCasinoLocal: FavoritCasinoStateType[];
  favoritCasinoUser: FavoritCasinoResponceType[];
}

const initialState: FavoritsState = {
  favoritCasinoLocal: [],
  favoritCasinoUser: [],
};

const favoritsSlice = createSlice({
  name: 'favorits',
  initialState,
  reducers: {
    setFavoritsLocalDelete: (state, action: PayloadAction<FavoritCasinoStateType>) => {
      state.favoritCasinoLocal = state.favoritCasinoLocal.filter(
        (item) => item.id !== action.payload.id
      );
    },
    setFavoritsLocalSelect: (state, action: PayloadAction<FavoritCasinoStateType>) => {
      state.favoritCasinoLocal.push(action.payload);
    },
    setFavoritsUser: (state, action: PayloadAction<FavoritCasinoResponceType[]>) => {
      state.favoritCasinoUser = action.payload;
    },
  },
});

export const {
  setFavoritsLocalDelete,
  setFavoritsLocalSelect,
  setFavoritsUser,
} = favoritsSlice.actions;
export default favoritsSlice;
