import React, { useState } from "react";
import { Provider } from "react-redux";
import "./App.scss";
import { Navigation } from "./navigation/Navigation";
import { useEffect } from "react";
import { store } from "./store";
import { MockSettingsResponce } from "mockdata/MockSettingsResponce";
import { SettingsResponceType } from "common/types/SettingsInfo/SettingsResponceType";
import { WebsiteUnavailable } from "pages";
import { AdminPanel } from "services";

enum loadingStatus {
 loading = "loading",
 unworking = "unworking",
 working = "working",
}

function App() {
 const [status, setStatus] = useState(loadingStatus.loading);
 async function setSettings() {
  try {
   const { data } = await AdminPanel.getDefaultSettings();
   if (
    data
     .filter((item) => item.key === "future_domain")[0]
     .value?.includes(window.location.origin)
   ) {
    setStatus(loadingStatus.unworking);
   } else {
    setStatus(loadingStatus.working);
   }
  } catch (e) {
   setStatus(loadingStatus.working);
  }
 }
 useEffect(() => {
  document.body.setAttribute("dir", "ltr");
 }, []);

 useEffect(() => {
  // @ts-ingore
  // Инициализация JivoSite
  if ((window as any).jivo_api) {
   (window as any).jivo_api.inited?.();
  }
  setSettings();
 }, []);

 return (
  <>
   {status === loadingStatus.loading && <div></div>}
   {status === loadingStatus.unworking && <WebsiteUnavailable />}
   {status === loadingStatus.working && (
    <Provider store={store}>
     <Navigation />
    </Provider>
   )}
  </>
 );
}

export default App;
