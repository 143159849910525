import axios from "axios";
const url = process.env.REACT_APP_API_ORIGIN_URL;

const apiTokenLess = axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json",
  },
});

export { apiTokenLess };
