type HeaderDataType = {
  status: string;
  outSideLink: boolean;
  path: string;
  name: string;
  additionalLinksStatus: boolean;
  icon?: string;
};

const HeaderData: HeaderDataType[] = [
  {
    status: "live",
    outSideLink: false,
    path: "/sports/live/event-view/",
    name: "navHeader.Live",
    additionalLinksStatus: false,
  },
  {
    status: "sport",
    outSideLink: false,
    path: "/sports/pre-match/event-view",
    name: "navHeader.Sport",
    additionalLinksStatus: true,
  },
  {
    status: "casino",
    outSideLink: false,
    path: "/casino/slots/-1",
    name: "navHeader.Casino",
    additionalLinksStatus: false,
  },
  {
    status: "livecasino",
    outSideLink: false,
    path: "/live-casino/home/-1/All",
    name: "navHeader.LiveCasino",
    additionalLinksStatus: false,
  },
  {
    status: "aviator",
    outSideLink: false,
    path: "/casino/game-view/806666",
    name: "navHeader.AVIATOR",
    additionalLinksStatus: false,
  },
  {
    status: "spaceman",
    outSideLink: false,
    path: "/casino/game-view/400043028",
    name: "navHeader.SPACEMAN",
    additionalLinksStatus: false,
  },
  {
    status: "jackpot",
    outSideLink: false,
    path: "/casino/jackpot",
    name: "navHeader.Jackpot",
    additionalLinksStatus: false,
  },
  {
    status: "promotions",
    outSideLink: false,
    path: "/promotions/all",
    name: "navHeader.Promotions",
    additionalLinksStatus: false,
  },
  {
    status: "games",
    outSideLink: false,
    path: "/games",
    name: "navHeader.Games",
    additionalLinksStatus: false,
  },
];

export { HeaderData };
