import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { CasinoGameType } from "common/types/Casino/CasinoGameType";
import { usePlayersWindows } from "hooks/usePlayersWindows";
import { useTypeSelector } from "hooks/useTypeSelector";

import { LeftWindowPlay } from "./playWindows/LeftWindowPlay/LeftWindowPlay";
import { RightWindowPlay } from "./playWindows/RightWindowPlay/RightWindowPlay";

import "./CasinoPlayer.scss";

interface ICasinoPlayer {
  seGameSelectOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CasinoPlayer = ({ seGameSelectOpen }: ICasinoPlayer) => {
  const role = useTypeSelector((data) => data.accountInfoReducer.role);
  const favoritsGamesUser = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoUser
  );
  const favoritsGamesLocal = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoLocal
  );
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );

  const [searchParams] = useSearchParams();

  const openGamesUrl = searchParams.get("openGames") || "";
  const gameNamesUrl = searchParams.get("gameNames") || "";

  const [casinoBanner, setCasinoBanner] = useState("");
  const [casinoLink, setCasinoLink] = useState(["", ""]);

  const [openGames, setOpenGames] = useState<string[]>(["", ""]);
  const [gameNames, setGameNames] = useState<string[]>(["", ""]);

  const [gamesSelected, setGamesSelected] = useState<CasinoGameType[]>([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [openWindows, setOpenWindows] = useState(1);


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  usePlayersWindows({
    role: role,
    openGamesUrl: openGamesUrl,
    setOpenGames: setOpenGames,
    gameNamesUrl: gameNamesUrl,
    setGameNames: setGameNames,
    openGames: openGames,
    gameNames: gameNames,
    language: language,
    casinoLink: casinoLink,
    gamesSelected: gamesSelected,
    setGamesSelected: setGamesSelected,
    setCasinoBanner: setCasinoBanner,
    setCasinoLink: setCasinoLink,
  });

  const hnadleOpenRightWindow = () => {
    setOpenWindows(2);
  };

  const handleCloseRightWindow = () => {
    setOpenWindows(1);
  };


  return (
    <div
      className="casino-full-game-bg"
      style={
        openGames[0] && !openGames[1]
          ? {
              backgroundImage: `url(${casinoBanner})`,
            }
          : {}
      }
    >
      <div
        className={`casino-full-game-container num-casino-games-${openWindows}`}
      >
        <LeftWindowPlay
          gamesSelected={gamesSelected}
          favoritsGamesUser={favoritsGamesUser}
          favoritsGamesLocal={favoritsGamesLocal}
          casinoLink={casinoLink}
          openGames={openGames}
          windowWidth={windowWidth}
          openWindows={openWindows}
        />
        {openWindows === 1 ? null : (
          <RightWindowPlay
            gamesSelected={gamesSelected}
            favoritsGamesUser={favoritsGamesUser}
            favoritsGamesLocal={favoritsGamesLocal}
            casinoLink={casinoLink}
            openGames={openGames}
            windowWidth={windowWidth}
            openGamesUrl={openGamesUrl}
            gameNamesUrl={gameNamesUrl}
            openWindows={openWindows}
            setGamesSelected={setGamesSelected}
            seGameSelectOpen={seGameSelectOpen}
          />
        )}
      </div>
      <div className="casino-full-game-window-icons">
        <i
          className={`bc-i-window-one ${openWindows === 1 ? "active" : ""} `}
          onClick={handleCloseRightWindow}
        />
        <i
          className={`bc-i-window-two ${openWindows === 2 ? "active" : ""} `}
          onClick={hnadleOpenRightWindow}
        />
      </div>
    </div>
  );
};

export { CasinoPlayer };
