import React, { useState } from "react";
import "./InputBirthDay.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";
import { DatePickerCustom } from "components/DatePickerCustom/DatePickerCustom";

interface IInputBirthDay {
  birthdayData: Date | null;
  setBirthdayData: React.Dispatch<React.SetStateAction<Date | null>>;
  errorsRequest: any
}

const InputBirthDay = ({ birthdayData, setBirthdayData, errorsRequest }: IInputBirthDay) => {
  return (
    <div className="entrance-f-item-bc">
      <DatePickerCustom
        startDate={birthdayData}
        setStartDate={setBirthdayData}
      />
    </div>
  );
};

export { InputBirthDay };
