import { t } from "i18next";
import React from "react";
import { CategoriesType } from "common/types/Casino/CategoriesType";
import "./Categories.scss";
import { CategoriesItem } from "./CategoriesUi/CategoriesItem/CategoriesItem";

interface ICategories {
  categoryList: CategoriesType | object  | any;
  selectedCategory: number;
  setSelectedCategory: React.Dispatch<React.SetStateAction<number>>;
}

const Categories = ({
  categoryList,
  selectedCategory,
  setSelectedCategory,
}: ICategories) => {
  return (
    <div className="casino-category-chooser-container">
      <div className="page-view-more">
        <h3 className="view-title-more ellipsis custom-font-size">{t("casino.Categories")}</h3>
      </div>
      <div className="horizontal-sl-list casino-horizontal-sl-list-bc horizontal-items-expanded ">
        {/* <i className="horizontal-sl-arrow bc-i-small-arrow-left" /> */}
        <div
          className="horizontal-sl-wheel"
          style={{ transform: `translateX(0px)` }}
        >
          {Object.keys(categoryList).map((item) => {
            return (
              <CategoriesItem
                key={categoryList[item].id}
                categoryItem={categoryList[item]}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            );
          })}
        </div>
        {/* <i className="horizontal-sl-arrow bc-i-small-arrow-right" /> */}
      </div>
    </div>
  );
};

export { Categories };
