import React from "react";
import freeSpin from "accets/images/png/freespins/free-spin.png";

import "./FreeSpin.scss";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useTypeSelector } from "hooks/useTypeSelector";

const FreeSpin = () => {
  const { balance } = useTypeSelector((data) => data.accountInfoReducer);
  const [searchParams] = useSearchParams();

  const profile = searchParams.get("profile") || "";
  return (
    <>
      {profile ? null : (
        <div className="free-spins-container">
          <Link
            className="frespin-cart"
            to={{
              search: "profile=open&account=bonuses&page=casino-free-spins",
            }}
          >
            <img src={freeSpin} alt="freeSpins" width={64} height={64} />
            <span>
              Free Spins: {balance.active_bonus?.settings.freespin_quantity}
            </span>
          </Link>
        </div>
      )}
    </>
  );
};

export { FreeSpin };
