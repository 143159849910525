import { ContentType } from "common/types/Content/ContentType";

const contentDefault: ContentType = {
  mobileFooter: {
    id: 5,
    position: "mobile",
    content: "",
  },
  desktopFooter: {
    id: 4,
    position: "desktop",
    content: "",
  },
  header: {
    id: 2,
    settings: null,
    items: [
      {
        id: 1,
        title: "Spor",
        icon: null,
        image: null,
        badge: "top",
        type: "link",
        link: "sports",
        link_type: null,
        active: 1,
        dropdownItems: [],
      },
      {
        id: 6,
        title: null,
        icon: null,
        image: null,
        badge: null,
        type: "dropdown",
        link: null,
        link_type: null,
        active: 1,
        dropdownItems: [
          {
            id: 7,
            title: "Leap",
            icon: null,
            image: null,
            badge: null,
            type: "link",
            link: "promotions",
            link_type: null,
            active: 1,
            dropdownItems: [],
          },
          {
            id: 8,
            title: "Virtual",
            icon: null,
            image: null,
            badge: null,
            type: "link",
            link: "promotions",
            link_type: null,
            active: 1,
            dropdownItems: [],
          },
          {
            id: 9,
            title: "Virtual Games",
            icon: null,
            image: null,
            badge: null,
            type: "link",
            link: "promotions",
            link_type: null,
            active: 1,
            dropdownItems: [],
          },
        ],
      },
      {
        id: 3,
        title: "Casino",
        icon: null,
        image: null,
        badge: "top",
        type: "link",
        link: "casino",
        link_type: null,
        active: 1,
        dropdownItems: [],
      },
      {
        id: 4,
        title: "Canlı Casino",
        icon: null,
        image: null,
        badge: null,
        type: "link",
        link: "live-casino",
        link_type: null,
        active: 1,
        dropdownItems: [],
      },
      {
        id: 5,
        title: "Promosyonlar",
        icon: null,
        image:
          "https://p4as3kol6z.s3.eu-central-1.amazonaws.com/uploads/casino-new/image-JDkbBzYtRtMmh1SD2bf4vKD5G7PCEJr97qMrNmoH.png",
        badge: null,
        type: "link",
        link: "promotions",
        link_type: null,
        active: 1,
        dropdownItems: [],
      },
    ],
  },
  mobileHeader: [],
  mobileMenu: {
    id: 2,
    position: "left",
    items: [],
  },
  quickNav: [],
  mobileMenuItems: [],
};

export { contentDefault };
