import React, { useEffect, useState } from "react";
import { Layout } from "components/layout";

import "./Main.scss";

import { Courusel } from "components/main/Courusel/Courusel";
import { JackpotBannerMain } from "components/main/JackpotBannerMain/JackpotBannerMain";
import { ProductBannerMain } from "components/main/ProductBannerMain/ProductBannerMain";
import { MainCasinoList } from "components/main/MainCasinoList/MainCasinoList";
import { MainLiveList } from "components/main/MainLiveList/MainLiveList";
import { MainGamesList } from "components/main/MainGamesList/MainGamesList";

import { useTypeSelector } from "hooks/useTypeSelector";

import { BananersEnum } from "common/types/Banners/BannersList";

const Main = () => {
 const [panelOpen, setPanelOpen] = useState(true);
 const [betsLipOpen, setBetsLipOpen] = useState(false);
 const bannersList = useTypeSelector((data) => data.accountInfoReducer.banners);
 return (
  <Layout
   addClasses={` is-home-page ${panelOpen ? "smart-panel-is-visible" : ""}`}
   panelOnep={panelOpen}
   setPanelOpen={setPanelOpen}
   navStatus=""
   betsLipPage={""}
   betsLipOpen={betsLipOpen}
   setBetsLipOpen={setBetsLipOpen}
  >
   <Courusel
    mockCourusel={bannersList}
    filtredSelector={BananersEnum.Homepage}
   />
   <JackpotBannerMain />
   <ProductBannerMain enumType={BananersEnum.MainBigList} />
   <ProductBannerMain enumType={BananersEnum.MainInvestmentList} isDepositParams/>
   <ProductBannerMain enumType={BananersEnum.MainSmallList} />
   <MainCasinoList />
   <MainLiveList />
   <MainGamesList />
  </Layout>
 );
};

export { Main };
