import React, { useState } from "react";
import "./AccordionProviders.scss";

interface IAccordionProviders {
  providersList: any;
  selectedProviders: any;
  setSelectedProviders: any;
  filteredData: any;
  searchInput: string;
}

const AccordionProviders = ({
  providersList,
  selectedProviders,
  setSelectedProviders,
  filteredData,
  searchInput,
}: IAccordionProviders) => {
  const [accordingOpen, setAccordingOpen] = useState(false);

  const handleOpenAccardion = () => {
    setAccordingOpen((prev) => !prev);
  };
  const handleSelectItem = (item: string[]) => {
    setSelectedProviders((prevState: string[]) => {
      const newState = [...prevState];
      item.forEach((newItem: string) => {
        if (newState.includes(newItem)) {
          newState.splice(newState.indexOf(newItem), 1);
        } else {
          newState.push(newItem);
        }
      });
      return newState;
    });
  };
  return (
    <div
      className={`accordion-items-container ${
        accordingOpen ? "" : "collapsed"
      } `}
    >
      <div className="accordion-items">
        <div className="accordion-items-resize">
          {searchInput === ""
            ? Object.keys(providersList).map((item: any) => {
                return (
                  <span
                    title={providersList[item][0].name}
                    key={providersList[item][0].id}
                    onClick={() =>
                      handleSelectItem(
                        providersList[item].map((item: any) => item.id)
                      )
                    }
                  >
                    <button
                      className={`btn s-big accordion-button ${
                        selectedProviders.includes(providersList[item][0].id)
                          ? "a-outline"
                          : ""
                      } `}
                      type="button"
                    >
                      {providersList[item][0].name === "Amusenet"
                        ? "Amusnet"
                        : providersList[item][0].name}
                    </button>
                  </span>
                );
              })
            : Object.keys(filteredData).map((item: any) => {
                return (
                  <span
                    title={filteredData[item][0].name}
                    key={filteredData[item][0].id}
                    onClick={() =>
                      handleSelectItem(
                        filteredData[item].map((item: any) => item.id)
                      )
                    }
                  >
                    <button
                      className={`btn s-big accordion-button ${
                        selectedProviders.includes(filteredData[item][0].id)
                          ? "a-outline"
                          : ""
                      } `}
                      type="button"
                    >
                      {filteredData[item][0].name === "Amusenet"
                        ? "Amusnet"
                        : filteredData[item][0].name}
                    </button>
                  </span>
                );
              })}
        </div>
      </div>
      <i
        className={`accordion-items-container-arrow bc-i-small-arrow-${
          accordingOpen ? "up" : "down"
        }`}
        onClick={handleOpenAccardion}
      />
    </div>
  );
};

export { AccordionProviders };
