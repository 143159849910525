import React, { useEffect, useRef, useState } from "react";
import { Layout } from "components/layout";
import { TopBar } from "components/jackpot/topBar/TopBar";
import { LabelBlock } from "components/jackpot/labelBlock/LabelBlock";
import { LevelList } from "components/jackpot/levelList/LevelList";
import "./Jackpot.scss";
import { JackpotsSearch } from "components/jackpot/jackpotsSearch/JackpotsSearch";
import { JackpotList } from "components/jackpot/jackpotList/JackpotList";
import { CasinoIconGameType } from "common/types/Casino/CasinoIconGameType";
import { useJackpotGames } from "hooks/useJackpotGames";

const Jackpot = () => {
  const [panelOnep, setPanelOpen] = useState(true);
  const [betsLipOpen, setBetsLipOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingGames, setLoadingGames] = useState(true);
  const [loadingGamesAdd, setLoadingGamesAdd] = useState(false);
  const [loadStop, setLoadStop] = useState(false);

  const [casinoGamesList, setCasinoGamesList] = useState<
    CasinoIconGameType[] | []
  >([]);

  const [seacrhGames, setSeacrhGames] = useState("");
  const [jackpotBackground, setJackpotBackground] = useState("egt");
  const page = useRef(1);

  useJackpotGames({
    seacrhGames,
    page,
    loadStop,
    setLoadingGames,
    setLoadingGamesAdd,
    setCasinoGamesList,
    setLoadStop,
  });

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);
  return (
    <Layout
      addClasses={`${
        betsLipOpen ? "betslip-Full" : "betslip-Hidden"
      } jackpot compact-footer page-full-size ${
        panelOnep ? "smart-panel-is-visible" : ""
      }`}
      panelOnep={panelOnep}
      setPanelOpen={setPanelOpen}
      navStatus="jackpot"
      betsLipPage={""}
      betsLipOpen={betsLipOpen}
      setBetsLipOpen={setBetsLipOpen}
    >
      <div
        className={`casinoJackpotWrapper-bc  ${
          jackpotBackground === "egt" ? "EGT" : "FGS"
        } `}
      >
        <TopBar
          jackpotBackground={jackpotBackground}
          setJackpotBackground={setJackpotBackground}
        />
        <LabelBlock />
        <LevelList />
        <JackpotsSearch
          seacrhGames={seacrhGames}
          setSeacrhGames={setSeacrhGames}
        />
        <JackpotList
          casinoGamesList={casinoGamesList}
          loadingGamesAdd={loadingGamesAdd}
        />
      </div>
    </Layout>
  );
};

export { Jackpot };
