import React from "react";

import "./TransationList.scss";
import {
  statusEnum,
  transactionsType,
} from "common/types/AdminInfo/transactionsType/transactionsType";

interface ITransationList {
  transactionList?: transactionsType[];
  selectedType: string;
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const TransationList = ({ transactionList, selectedType }: ITransationList) => {
  return (
    <div className="container">
      {transactionList?.map((item) => {
        return (
          <div className="card" key={item.id}>
            <div className="left">
              <div className="title">{`${selectedType} - ${item.payment_method}`}</div>
              <div className="date">{item.created_at}</div>
            </div>
            <div className={`right`}>
              <div
                className={`status ${
                  item.status === statusEnum.completed
                    ? statusEnum.completed
                    : statusEnum.cancaled
                }`}
              >
                {/* {item.status === statusEnum.completed
                  ? capitalizeFirstLetter(statusEnum.completed)
                  : capitalizeFirstLetter(statusEnum.cancaled)} */}
                  {capitalizeFirstLetter(item.status)}
              </div>
              <div className="amount">
                Amount: {item.amount}
                {item.currency}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { TransationList };
