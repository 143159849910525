import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CasinoIconGameType } from "common/types/Casino/CasinoIconGameType";
import { useTypeSelector } from "hooks/useTypeSelector";
import "./HorizontalListItem.scss";
import { setModalLogin } from "store/reducers/modalsReducer";

interface IHorizontalListItem {
  itemInfo: CasinoIconGameType;
}

const HorizontalListItem = ({ itemInfo }: IHorizontalListItem) => {
  const role = useTypeSelector((data) => data.accountInfoReducer.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOpenDemo = () => {
    const setParamsPlay = () => {
      const params = new URLSearchParams();
      params.set("openGames", itemInfo.game_provider.slug);
      params.set("gameNames", itemInfo.slug);
      const search = "?" + params.toString();
      navigate({ search });
    };
    if (role === "unauth") {
      dispatch(setModalLogin(true));
    } else {
      setParamsPlay();
    }
  };
  return (
    <div
      onClick={handleOpenDemo}
      className={`horizontal-sl-item-bc`}
      data-id={itemInfo.id}
      title={itemInfo.original_name}
    >
      <i className={`horizontal-sl-icon-bc bc-i-default-icon`} />
      <p className="horizontal-sl-title-bc">{itemInfo.original_name}</p>
    </div>
  );
};

export { HorizontalListItem };
