import React from "react";

import "./WitdhrowExtraData.scss";
import {
  AccountNumberInput,
  AddNotesInput,
  AmountInput,
  FullNameInput,
  BankCodeInput,
  IbanInput,
  IdInput,
  LastCharsInput,
  DescriptionInput,
  WalletIdInput,
} from "./UI";

interface IWitdhrowExtraData {
  bankCodeValue?: string | null;
  setBankCodeValue?: React.Dispatch<React.SetStateAction<string | null>>;
  fullNameValue?: string | null;
  setFullNameValue?: React.Dispatch<React.SetStateAction<string | null>>;
  accountNumberValue?: string | null;
  setAccountNumberValue?: React.Dispatch<React.SetStateAction<string | null>>;
  ibanValue?: string | null;
  setIbanValue?: React.Dispatch<React.SetStateAction<string | null>>;
  lastCharsValue?: string | null;
  setLastCharsValue?: React.Dispatch<React.SetStateAction<string | null>>;
  idValue?: string | null;
  setIdValue?: React.Dispatch<React.SetStateAction<string | null>>;
  addNotesValue?: string | null;
  setAddNotesValue?: React.Dispatch<React.SetStateAction<string | null>>;
  descriptionValue?: string | null;
  setDescriptionValue?: React.Dispatch<React.SetStateAction<string | null>>;
  walletIdValue?: string | null;
  setWalletIdValue?: React.Dispatch<React.SetStateAction<string | null>>;
  payment_name?: string;
  payment_aggregator_id?: number;
  id?: number;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const WitdhrowExtraData = ({
  bankCodeValue,
  setBankCodeValue,
  fullNameValue,
  setFullNameValue,
  ibanValue,
  setIbanValue,
  lastCharsValue,
  setLastCharsValue,
  idValue,
  setIdValue,
  addNotesValue,
  setAddNotesValue,
  inputValue,
  setInputValue,
  payment_name,
  payment_aggregator_id,
  id,
  accountNumberValue,
  setAccountNumberValue,
  descriptionValue,
  setDescriptionValue,
  walletIdValue,
  setWalletIdValue,
}: IWitdhrowExtraData) => {
  return (
    <>
      {payment_name === "manual" && (
        <>
          <WalletIdInput
            walletIdValue={walletIdValue}
            setWalletIdValue={setWalletIdValue}
          />{" "}
          <AmountInput inputValue={inputValue} setInputValue={setInputValue} />
        </>
      )}
      {id === 28 && (
        <>
          <AmountInput inputValue={inputValue} setInputValue={setInputValue} />
          <WalletIdInput
            walletIdValue={walletIdValue}
            setWalletIdValue={setWalletIdValue}
          />
        </>
      )}
      {payment_aggregator_id !== 2 &&
        id !== 28 && id !== 9 &&
        payment_name !== "manual" && (
          <>
            <IbanInput ibanValue={ibanValue} setIbanValue={setIbanValue} />
            <AmountInput
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          </>
        )}
      {payment_aggregator_id !== 2 && id == 9 && payment_name !== "manual" && (
        <>
          <AmountInput inputValue={inputValue} setInputValue={setInputValue} />
          <WalletIdInput
            walletIdValue={walletIdValue}
            setWalletIdValue={setWalletIdValue}
          />
        </>
      )}
    </>
  );
};

export { WitdhrowExtraData };
