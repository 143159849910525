import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useTypeSelector } from "hooks/useTypeSelector";
import { FavoritGamesLocal } from "./FavoritGamesLocal/FavoritGamesLocal";
import { FavoritGamesUser } from "./FavoritGamesUser/FavoritGamesUser";
import "./SidePanelFavorits.scss";

interface iSidePanelFavorits {
  favorits: boolean;
  setFavorits: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidePanelFavorits = ({ favorits, setFavorits }: iSidePanelFavorits) => {
  const favoritCasinoLocal = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoLocal
  );
  const favoritCasinoUser = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoUser
  );
  const roly = useTypeSelector((data) => data.accountInfoReducer.role);
  const [selectedSecondTabItem, setSelectedSecondTabItem] = useState("sport");
  const [selectedSportType, setSelectedSportType] = useState("match");
  const wrapperRef = useRef(null);
  const role = useTypeSelector((data) => data.accountInfoReducer.role);

  const hadnleSelectTabItem = (item: string) => {
    setSelectedSecondTabItem(item);
  };

  const handleSelectSportType = (item: string) => {
    setSelectedSportType(item);
  };

  const handleClosePanel = () => {
    setFavorits(false);
  };

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      handleClosePanel();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);

  return (
    <div className={`sidePanel-holder-bc ${favorits ? "" : "hidden"}`}>
      <div className="sidePanel-holder" ref={wrapperRef}>
        <div className="overlay-header">
          <i className="bc-i-close-remove" onClick={handleClosePanel} />
          <span>{t("admin.Favorites")}</span>
        </div>
        <div className="smart-panel-tabs-bc">
          <div className="second-tabs-bc">
            <div
              className={`tab-bc selected-underline ${
                selectedSecondTabItem === "sport" ? "active" : ""
              }`}
              onClick={() => {
                hadnleSelectTabItem("sport");
              }}
              title=""
            >
              <span>{t("navHeader.Sport")}</span>
              <span>(0)</span>
            </div>
            <div
              className={`tab-bc selected-underline ${
                selectedSecondTabItem === "casino" ? "active" : ""
              }`}
              onClick={() => {
                hadnleSelectTabItem("casino");
              }}
              title=""
            >
              <span>{t("navHeader.Casino")}</span>
              <span>
                (
                {roly === "player"
                  ? favoritCasinoUser.length
                  : favoritCasinoLocal.length}
                )
              </span>
            </div>
          </div>
          <div className="sp-fav-body-bc">
            {selectedSecondTabItem === "sport" ? (
              <div className="second-tabs-bc">
                <div
                  className={`tab-bc selected-underline ${
                    selectedSportType === "competition" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleSelectSportType("competition");
                  }}
                  title=""
                >
                  <span>Competition</span>
                  <span>(0)</span>
                </div>
                <div
                  className={`tab-bc selected-underline ${
                    selectedSportType === "match" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleSelectSportType("match");
                  }}
                  title=""
                >
                  <span>Match</span>
                  <span>(0)</span>
                </div>
              </div>
            ) : null}
            {selectedSecondTabItem === "sport" ? (
              <p className="empty-b-text-v-bc">{t("admin.donthavefavorit")}</p>
            ) : (
              <>
                {role === "player" ? (
                  <FavoritGamesUser />
                ) : (
                  <FavoritGamesLocal />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { SidePanelFavorits };
