import { t } from "i18next";
import React from "react";
import "./AmountSelect.scss";
import { ImockSelectUserList } from "../../BuddyTransfer";

interface IAmountSelect {
  selectedRadioUser: ImockSelectUserList;
}

const AmountSelect = ({ selectedRadioUser }: IAmountSelect) => {
  return (
    <div className="buddy-transfer-item transfer-amount">
      <div className="form-control-bc default ">
        <label className="form-control-label-bc inputs">
          <input
            type="text"
            inputMode="decimal"
            className="form-control-input-bc"
            name="amount"
            step="0.01"
            defaultValue=""
          />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
            {selectedRadioUser.inputPlaceholder}
          </span>
        </label>
      </div>
      <button className="casino-game-tr-button btn " type="button" title="MAX">
        <span>{t("admin.MAX")}</span>
      </button>
    </div>
  );
};

export { AmountSelect };
