import { t } from "i18next";
import React, { useEffect, useState } from "react";
import "./History.scss";
import { TransitionType } from "./HistoryUi/transitionType/TransitionType";
import { transactionsType } from "common/types/AdminInfo/transactionsType/transactionsType";
import { AdminPanel } from "services";
import { TransationList } from "./HistoryUi/TransationList/TransationList";

const History = () => {
 const [selectedType, setSelectedType] = useState("Deposit");
 const [selectedTypePrev, setSelectedTypePrev] = useState(selectedType);
 const [transactionList, setTransactionList] = useState<transactionsType[]>();
 const [loading, setLoading] = useState(true);
 console.log(selectedType);
 function setTransactionWithdrawalsInfo() {
  setLoading(true);
  AdminPanel.withdrawals(
   `?user-dashboard/transactions?limit=100&page=1&type=${selectedType}&status=`
  )
   .then((res) => {
    setTransactionList(res.data);
   })
   .catch((error) => {
    console.error(error);
   })
   .finally(() => {
    setLoading(false);
   });
 }
 function setTransactionDepositInfo() {
  setLoading(true);
  AdminPanel.transactions(
   `?user-dashboard/transactions?limit=100&page=1&type=${selectedType}&status=`
  )
   .then((res) => {
    setTransactionList(res.data);
   })
   .catch((error) => {
    console.error(error);
   })
   .finally(() => {
    setLoading(false);
   });
 }
 const handleShow = () => {
  setSelectedType(selectedTypePrev);
  if (selectedTypePrev === "Deposit") {
   setTransactionDepositInfo();
  } else {
   setTransactionWithdrawalsInfo();
  }
 };
 useEffect(() => {
  handleShow();
 }, []);
 return (
  <>
   <div className="overlay-header">{t("admin.TransactionHistory")}</div>
   <div
    className="u-i-e-p-p-content-bc u-i-common-content"
    data-scroll-lock-scrollable=""
   >
    <div className="componentFilterWrapper-bc">
     <div className="componentFilterBody-bc">
      <div className="componentFilterElsWrapper-bc">
       <div className="filter-form-w-bc">
        <div className="componentFilterBody-content">
         <TransitionType
          setSelectedType={setSelectedTypePrev}
          selectedType={selectedTypePrev}
         />
        </div>
        <div className="u-i-p-c-filter-footer-bc">
         <button
          className="btn a-color "
          type="submit"
          title="Show"
          onClick={handleShow}
         >
          <span>{t("admin.Show")}</span>
         </button>
        </div>
       </div>
      </div>
     </div>
    </div>
    {!transactionList ? (
     <p className="empty-b-text-v-bc">
      {t("admin.Therearenodatafortheselectedtimeperiod")}
     </p>
    ) : (
     <>
      {loading ? null : (
       <TransationList
        selectedType={selectedType}
        transactionList={transactionList}
       />
      )}
     </>
    )}
   </div>
  </>
 );
};

export { History };
