import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";

import { PageType } from "common/types/PageType/PageType";
import "./CompanyInfoModal.scss";

interface ICompanyInfoModal {
  setFooterPageInfo: React.Dispatch<React.SetStateAction<PageType | null>>;
  footerPageInfo: PageType | null;
  setIsFooterContent: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompanyInfoModal = ({
  footerPageInfo,
  setFooterPageInfo,
  setIsFooterContent,
}: ICompanyInfoModal) => {
  const wrapperRef = useRef(null);

  const navigate = useNavigate();

  const handleCloseWindow = () => {
    setFooterPageInfo(null);
    setIsFooterContent(false);
    navigate("", {
      replace: true,
    });
  };
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      handleCloseWindow();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  return (
    <div className="popup-holder-bc windowed  content-manager ">
      <div className="popup-middleware-bc">
        <div className="popup-inner-bc" ref={wrapperRef}>
          <i
            className="e-p-close-icon-bc bc-i-close-remove"
            onClick={() => handleCloseWindow()}
          />
          <div className="help-popup">
            <div className="help-popup-title">
              <span className="help-popup-title-text ellipsis">
                {footerPageInfo?.title}
              </span>
            </div>
            {footerPageInfo?.content && parse(footerPageInfo?.content)}
          </div>
        </div>
      </div>
    </div>
  );
};

export { CompanyInfoModal };
