import React, { useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import "./Footer.scss";
import image1 from "accets/images/png/footerLinksSocial/canlı-logo-png.png";
import image2 from "accets/images/png/footerLinksSocial/facebook.png";
import image3 from "accets/images/png/footerLinksSocial/instagram-logo.png";
import image4 from "accets/images/png/footerLinksSocial/telegram-logo.png";
import image5 from "accets/images/png/footerLinksSocial/x-logo.png";
import image6 from "accets/images/png/footerLinksSocial/youtube-logo.png";
import logo from "accets/images/png/logo.png";

import topBannerLocal from "accets/images/png/footerBanners/topBanner.png";
import centerBannerLocal from "accets/images/png/footerBanners/centerBanner.png";
import bottomBannerLocal from "accets/images/png/footerBanners/bottomBanner.png";
import { BananersEnum } from "common/types/Banners/BannersList";
import { useTypeSelector } from "hooks/useTypeSelector";
import logoVeri from "accets/images/jpeg/logoVeri.jpeg";

const Footer = () => {
 const [footerOpen, setFooterOpen] = useState(false);
 const handleOpenFooter = () => {
  setFooterOpen((prev) => !prev);
 };

 const topBanner = useTypeSelector((data) =>
  data.accountInfoReducer.banners.filter(
   (item) => item.type === BananersEnum.FooterTopBaner
  )
 );

 const centerBanner = useTypeSelector((data) =>
  data.accountInfoReducer.banners.filter(
   (item) => item.type === BananersEnum.FooterCenterBaner
  )
 );

 const bottomBanner = useTypeSelector((data) =>
  data.accountInfoReducer.banners.filter(
   (item) => item.type === BananersEnum.FooterBottomBaner
  )
 );

 const paymentBanners = useTypeSelector((data) =>
  data.accountInfoReducer.banners.filter(
   (item) => item.type === BananersEnum.FooterPaymentBanners
  )
 );

 const { content } = useTypeSelector(
  (data) => data.settingsReducer.content.desktopFooter
 );

 return (
  <div
   className={`layout-footer-holder-bc ${
    footerOpen ? "active" : ""
   } footer-custom-bg`}
  >
   <i
    className="open-footer-icon bc-i-arrow-drop-down-up"
    onClick={handleOpenFooter}
   />
   <div className="footer-bc">
    <div className="ftr-link">
     <div className="ftr-n-item-bc" style={{ width: "60%" }}>
      <div className="ftr-n-l-cells-bc">
       <h4 className="footer-title custom-footer-title">Hakkımızda</h4>
       <Link
        target="_self"
        className="ftr-n-link-bc undefined"
        to={{
         search: "?helpPageContent=30749",
        }}
       >
        <span>Firma Bilgileri</span>
       </Link>
       <Link
        target="_self"
        className="ftr-n-link-bc undefined"
        to={{
         search: "?helpPageContent=27091",
        }}
       >
        <span>İletişim</span>
       </Link>
       <Link
        target="_self"
        className="ftr-n-link-bc undefined"
        to={{
         search: "?helpPageContent=27089",
        }}
       >
        <span>Sorumlu Oyunculuk</span>
       </Link>
      </div>
      <div className="ftr-n-l-cells-bc">
       <h4 className="footer-title">Kurallar ve Şartlar</h4>
       <Link
        target="_self"
        className="ftr-n-link-bc undefined"
        to={{
         search: "?helpPageContent=17125",
        }}
       >
        <span>Genel Kurallar ve Şartlar</span>
       </Link>
      </div>
      <div className="ftr-n-l-cells-bc">
       <h4 className="footer-title">Hüküm ve Koşullar</h4>
       <Link
        target="_self"
        className="ftr-n-link-bc undefined"
        to={{
         search: "?helpPageContent=17125",
        }}
       >
        <span>Hüküm ve Koşullar</span>
       </Link>
      </div>
      <div className="ftr-n-l-cells-bc">
       <h4 className="footer-title">Gizlilik</h4>
       <Link
        target="_self"
        className="ftr-n-link-bc undefined"
        to={{
         search: "?helpPageContent=30750",
        }}
       >
        <span>Gizlilik İlkesi</span>
       </Link>
       <Link
        target="_self"
        className="ftr-n-link-bc undefined"
        to={{
         search: "?helpPageContent=30752",
        }}
       >
        <span>Çerez Bildirimi</span>
       </Link>
      </div>
     </div>
     <div style={{ width: "40%" }}>
      <img src={logo} width={190} height={36} />
      <p className="footer_info-text">
       Casinopalas.com | Casino - Canlı Casino - Poker – Yetenek Oyunları -
       Kahve Oyunları CasinoPalas size tüm casino oyunlarının yanında poker ve
       tombala gibi oyunları CasinoPalas güvencesiyle internet ortamında sunar.
       <br />
       <br />
       Zeppelin, Plinko, Sanal Oyunlar, Kazı kazan ve daha fazlasını
       CasinoPalas&apos;ın güvenli ortamında ve kaliteli hizmetiyle
       oynayabilirsiniz. Webde, tablette ve mobilde; her nerede olursanız olun
       CasinoPalas güvencesi hep yanınızda!
      </p>
      <div className="links_container">
       <img src={image1} className="footer_social" />
       <img src={image2} className="footer_social" />
       <img src={image3} className="footer_social" />
       <Link to="https://t.me/casinopalasgiris">
        <img src={image4} className="footer_social" />
       </Link>
       <Link to="https://x.com/casinopalass">
        <img src={image5} className="footer_social" />
       </Link>
       <img src={image6} className="footer_social" />
      </div>
     </div>
    </div>
    {/* asda------------------------------------------------------- */}
    <div className="footer-title">Regulations &amp; Partners</div>
    <div className="regulation-partner">
     <div className="ftr-partners-row-bc">
      <div className="ftr-partners-row-inner-bc ">
       <div rel="noreferrer" className="">
        <img
         className="ftr-partners-r-img"
         loading="lazy"
         src={
          topBanner[0]?.desktop_image
           ? topBanner[0]?.desktop_image
           : topBannerLocal
         }
         alt=""
        />
       </div>
      </div>
      <div className="ftr-partners-row-inner-bc ">
       <div rel="noreferrer" className="">
        <img
         className="ftr-partners-r-img"
         loading="lazy"
         src={
          centerBanner[0]?.desktop_image
           ? centerBanner[0]?.desktop_image
           : centerBannerLocal
         }
         alt=""
        />
       </div>
      </div>
      <div className="ftr-partners-row-inner-bc ">
       <div>
        <div
         style={{
          display: "flex",
          position: "relative",
          overflow: "hidden",
         }}
        >
         {bottomBanner[0]?.desktop_image ? (
          <div rel="nonoopener">
           <img
            alt=""
            style={{
             width: "100%",
             height: "auto",
             maxHeight: "100px",
             maxWidth: "100px",
             marginRight: "20px",
            }}
            src={
             bottomBanner[0]?.desktop_image
              ? bottomBanner[0]?.desktop_image
              : bottomBannerLocal
            }
           />
          </div>
         ) : null}
         {bottomBanner[1]?.desktop_image ? (
          <a
           rel="nonoopener noreferrer"
           target="_blank"
           href="https://licence-curacao-egaming.com/"
          >
           <img
            alt=""
            style={{
             width: "100%",
             height: "auto",
             maxHeight: "100px",
             maxWidth: "100px",
             marginRight: "20px",
            }}
            src={
             bottomBanner[1]?.desktop_image
              ? bottomBanner[1]?.desktop_image
              : bottomBannerLocal
            }
           />
          </a>
         ) : null}
         {bottomBanner[2]?.desktop_image ? (
          <div rel="nonoopener">
           <img
            alt=""
            style={{
             width: "100%",
             height: "auto",
             maxHeight: "100px",
             maxWidth: "100px",
             marginRight: "20px",
            }}
            src={
             bottomBanner[2]?.desktop_image
              ? bottomBanner[2]?.desktop_image
              : bottomBannerLocal
            }
           />
          </div>
         ) : null}
         <a
          className=" header-icon"
          target="_blank"
          href="https://licence-curacao-egaming.com/"
          rel="noreferrer"
         >
          <img
           alt=""
           style={{
            width: "100%",
            height: "auto",
            maxHeight: "100px",
            maxWidth: "100px",
            marginRight: "20px",
           }}
           src={logoVeri}
          />
         </a>
        </div>
       </div>
      </div>
     </div>
    </div>
    <div className="horizontal-sl-list casino-horizontal-sl-list-bc horizontal-items-expanded scroll-end ">
     <div className="horizontal-sl-wheel" />
     {paymentBanners &&
      paymentBanners.map((item) => {
       return (
        <img
         key={item.id}
         className="ftr-payments-row-img-bc"
         src={item.desktop_image}
         alt={item.title}
        />
       );
      })}

     <i className="horizontal-sl-arrow bc-i-small-arrow-right"></i>
    </div>
    <div className="ftr-copy-rights-bc">
     CasinoPalas.com is operated by Betimblue Technology B.V. a company
     registered in Curaçao, at Chuchubiweg 19, Willemstad holds a sublicense No
     8045/JAZ with Antillephone N.V. regulated and licensed by the Central
     Government of the Netherlands Antilles.
    </div>
   </div>
  </div>
 );
};

export { Footer };
