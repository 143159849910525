import React from "react";
import { useTypeSelector } from "hooks/useTypeSelector";
import "./UserInfo.scss";

const UserInfo = () => {
  const { user } = useTypeSelector((data) => data.accountInfoReducer);
  const handleCopyClick = async () => {
    try {
      if (user?.id) {
        await navigator.clipboard.writeText(user?.id.toString());
      }
    } catch (error) {
      console.error("Ошибка при копировании текста:", error);
    }
  };
  return (
    <div className="u-i-p-p-u-i-edit-button-bc">
      <span className="u-i-p-p-u-i-avatar-holder-bc">
        {/* {user !== null ? user?.firstname[0] : ""} */}
        {/* {user !== null ? user?.lastname[0] : ""} */}
      </span>
      <p className="u-i-p-p-u-i-identifiers-bc">
        <span className="u-i-p-p-u-i-d-username-bc ellipsis">
          {user.firstname} {user.lastname}
        </span>
        <span className="u-i-p-p-u-i-d-user-id-bc ellipsis">
          {user.id}
          <i
            className="u-i-p-p-u-i-d-user-id-copy-bc bc-i-copy"
            onClick={handleCopyClick}
          />
        </span>
      </p>
    </div>
  );
};

export { UserInfo };
