import React from "react";
import { useNavigate } from "react-router-dom";
import { useTypeSelector } from "hooks/useTypeSelector";
import "./PanelItemsPlayer.scss";

interface IhandleClick {
  profile: string;
  account: string;
  page: string;
}

interface IPanelItemsPlayer {
  setNotificationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setFavorits: React.Dispatch<React.SetStateAction<boolean>>;
  setSettings: React.Dispatch<React.SetStateAction<boolean>>;
  setBetsLipOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  betsLipOpen?: boolean;
}

const PanelItemsPlayer = ({
  setNotificationOpen,
  setFavorits,
  setSettings,
  setBetsLipOpen,
}: IPanelItemsPlayer) => {
  const navigate = useNavigate();

  const handleClick = ({ profile, account, page }: IhandleClick) => {
    const params = new URLSearchParams({
      profile,
      account,
      page,
    });
    navigate(`?${params.toString()}`, { replace: true });
  };
  const handleOpenNotification = () => {
    setNotificationOpen((prev) => !prev);
  };
  const handleOpenFavorits = () => {
    setFavorits((prev) => !prev);
  };

  const handleOpenSettings = () => {
    setSettings((prev) => !prev);
  };
  const handleOpenBetsLip = () => {
    if (setBetsLipOpen) {
      setBetsLipOpen((prev) => !prev);
    }
  };
  const favoritCasinoUser = useTypeSelector(
    (data) => data.favoritsReducer.favoritCasinoUser
  );
  return (
    <div className="hdr-smart-panel-holder-bc custom-margin">
      <button
        className="sp-button-bc "
        title="Bet History"
        data-component=""
        onClick={() =>
          handleClick({ profile: "open", account: "history", page: "bets" })
        }
      >
        <i className="sp-button-icon-bc bc-i-history" />
      </button>
      <button
        className="sp-button-bc "
        title="What's new"
        data-component="Notifications"
        onClick={handleOpenNotification}
      >
        <i
          id="smart-panel-notification-button-id"
          className="sp-button-icon-bc bc-i-notification"
        />
        <i className="  count-blink-even " data-badge="" />
      </button>
      <button
        className="sp-button-bc "
        title="Favorites"
        data-component="FavoriteGames"
        onClick={handleOpenFavorits}
      >
        <i
          id="smart-panel-favorites-button-id"
          className="sp-button-icon-bc bc-i-star"
        />
        {favoritCasinoUser.length > 0 ? (
          <i
            className=" count-odd-animation count-blink-odd "
            data-badge={favoritCasinoUser.length.toString()}
          ></i>
        ) : null}
        <i className="  count-blink-even " data-badge="" />
      </button>
      <button
        className="sp-button-bc "
        title="Messages"
        data-component="Messages"
        onClick={() =>
          handleClick({ profile: "open", account: "messages", page: "inbox" })
        }
      >
        <i className="sp-button-icon-bc bc-i-message" />
        <i className="  count-blink-even " data-badge="" />
      </button>
      <button
        className="sp-button-bc "
        title="Bonuses"
        data-component="Bonuses"
        onClick={() =>
          handleClick({ profile: "open", account: "bonuses", page: "sport" })
        }
      >
        <i className="sp-button-icon-bc bc-i-bonus" />
        <i className="  count-blink-even " data-badge="" />
      </button>
      <button
        className="sp-button-bc "
        title="Settings"
        data-component="Settings"
        onClick={handleOpenSettings}
      >
        <i
          id="smart-panel-settings-button-id"
          className="sp-button-icon-bc bc-i-settings"
        />
        <span className="sp-button-content-text-bc" />
      </button>
      <button
        className="sp-button-bc "
        type="button"
        title="Contact support"
        style={{}}
      >
        <i className="sp-button-icon-bc bc-i-live-chat" />
      </button>
      <button
        className="sp-button-bc "
        title="BetSlip"
        data-component="Betslip"
        onClick={handleOpenBetsLip}
      >
        <i className="sp-button-icon-bc bc-i-betslip" />
        <i className="  count-blink-even " data-badge="" />
      </button>
    </div>
  );
};

export { PanelItemsPlayer };
