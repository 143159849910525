import React, { useEffect, useState } from "react";
import { Layout } from "components/layout";
import "./Live.scss";
import { useTypeSelector } from "hooks/useTypeSelector";
import { CasinoInfo } from "services";
const Live = () => {
  const [panelOnep, setPanelOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [linkPlayer, setLinkPlayer] = useState("");
  const bearerToken = localStorage.getItem("token");
  const role = useTypeSelector((data) => data.accountInfoReducer.role);
  const lang = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );

  async function getLinkPLayer() {
    try {
      const res = await CasinoInfo.sportbookStartSession(lang);
      setLinkPlayer(res.data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }
  useEffect(() => {
    if (bearerToken && role === "player") {
      getLinkPLayer();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Layout
      addClasses={`compact-footer page-full-size live ${
        panelOnep ? "smart-panel-is-visible" : ""
      }`}
      panelOnep={panelOnep}
      setPanelOpen={setPanelOpen}
      navStatus={"live"}
      betsLipPage={"sport"}
    >
      {loading ? null : (
        <>
          {linkPlayer ? (
            <div>
              <iframe
                src={linkPlayer}
                width={"100%"}
                height={"100vh"}
                className="custom-iframe-container"
                style={{ position: "absolute" }}
              />
            </div>
          ) : (
            <div>
              <iframe
                src={`https://spwaio.igpxl.com/${lang}/sport?serverUrl=https://apispwaio.igpxl.com`}
                width={"100%"}
                height={"100vh"}
                className="custom-iframe-container"
                style={{ position: "absolute" }}
              />
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

export { Live };
