import React from "react";

import "./JackpotBannerMain.scss";
import { JackpotBanner } from "components";

const JackpotBannerMain = () => {
  return (
    <div className="hm-row-bc">
      <JackpotBanner
        title={"Casino Palas Jackpot"}
        amount={748761.49}
      ></JackpotBanner>
      {/*<JackpotBanner title={'AMUSNET INTERACTIVE'} amount={637761.49}></JackpotBanner>*/}
    </div>
  );
};

export { JackpotBannerMain };
