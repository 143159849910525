import React, { useEffect, useRef, useState } from "react";
import { CasinoIconGameType } from "common/types/Casino/CasinoIconGameType";
import { CasinoInfo } from "services";

import "./CasinoSelectList.scss";
import { GameSelectItem } from "./GameSelectItem/GameSelectItem";

interface ICasinoSelectList {
  setGameSelectOpen: React.Dispatch<React.SetStateAction<boolean>>;
  inputValue: string;
}

const CasinoSelectList = ({
  setGameSelectOpen,
  inputValue,
}: ICasinoSelectList) => {
  const page = useRef(1);
  const search = useRef("");

  useEffect(() => {
    search.current = inputValue;
    page.current = 0;
    setCasinoGames([]);
  }, [inputValue]);

  const [casinoGames, setCasinoGames] = useState<CasinoIconGameType[]>([]);

  const loadMore = () => {
    async function casinoGames() {
      page.current++;
      const res = await CasinoInfo.games(
        `/${6}?current_page=1&limit=25&page=${page.current}&is_mobile=true`
      );
      setCasinoGames((prevState) => prevState.concat(res.data.data));
    }
    async function casinoGamesSearch() {
      const res = await CasinoInfo.searchGames(
        `?current_page=1&limit=25&page=${page.current}&search=${search.current}&is_mobile=true`
      );
      setCasinoGames((prevState) => prevState.concat(res.data.data));
      page.current++;
    }
    if (search.current.length > 0) {
      casinoGamesSearch();
    } else {
      casinoGames();
    }
  };

  useEffect(() => {
    loadMore();
  }, [inputValue]);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
        const currentPosition = scrollTop + clientHeight;
        const maxPosition = scrollHeight;
        if (currentPosition / maxPosition >= 0.9) {
          loadMore();
        }
      };

      scrollContainer.addEventListener("scroll", handleScroll);

      return () => {
        scrollContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    page.current = 0;
  }, [search.current]);

  return (
    <div
      className="casino-category-types casino-category-types-full custom-scroll-container"
      id="casino_games_container"
      ref={scrollContainerRef}
    >
      <div className="infinite-scroll-component__outerdiv">
        <div
          className="infinite-scroll-component casino-category-games  custom-list-container"
          style={{ height: "auto", overflow: "auto" }}
        >
          {casinoGames.map((item) => {
            return (
              <GameSelectItem
                key={item.id}
                gameItem={item}
                setGameSelectOpen={setGameSelectOpen}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { CasinoSelectList };
