import React, { useEffect, useState } from "react";
import "./Withdraw.scss";
import { PayemntItem } from "./WithdrawUi/PayemntItem/PayemntItem";

import { userDeposit } from "common/types/AdminInfo/userDeposit";
import { AdminPanel } from "services";
import { t } from "i18next";
import { getPayment } from "./util/getPayment";
import { WitdhrowExtraData } from "./WithdrawUi";
import { AddNotesInput } from "./WithdrawUi/WitdhrowExtraData/UI";
import { useTypeSelector } from "hooks/useTypeSelector";

const Withdraw = () => {
  const id = useTypeSelector(
    (data) => data.accountInfoReducer.balance.active_bonus?.id
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalStatus = (item: boolean) => {
    setIsModalOpen(item);
  };
  const { active_bonus } = useTypeSelector(
    (data) => data.accountInfoReducer.balance
  );
  const [paymentMethods, setPaymentMethods] = useState<userDeposit[] | []>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<userDeposit>();
  const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(true);
  const [inputValue, setInputValue] = useState("");

  const [bankCodeValue, setBankCodeValue] = useState<string | null>(null);
  const [fullNameValue, setFullNameValue] = useState<string | null>(null);
  const [ibanValue, setIbanValue] = useState<string | null>(null);
  const [lastCharsValue, setLastCharsValue] = useState<string | null>(null);
  const [idValue, setIdValue] = useState<string | null>(null);
  const [addNotesValue, setAddNotesValue] = useState<string | null>(null);
  const [accountNumberValue, setAccountNumberValue] = useState<string | null>(
    null
  );
  const [descriptionValue, setDescriptionValue] = useState<string | null>(null);
  const [walletIdValue, setWalletIdValue] = useState<string | null>(null);

  const handleMoveToPay = () => {
    if (active_bonus) {
      handleModalStatus(true);
    } else {
      getPayment({
        inputValue: inputValue,
        extra_data: {
          accountNumber: accountNumberValue,
          bankCode: bankCodeValue,
          description: descriptionValue,
          iban: ibanValue,
          id: idValue,
          nameSurname: fullNameValue,
          notes: addNotesValue,
          tcNum: "2312",
          wallet_id: walletIdValue,
        },
        service_id: Number(selectedPaymentMethod?.service_id),
      });
    }
  };
  const handlePayAfterBonus = async () => {
    try {
      await AdminPanel.deleteBonus({ data: id || 1 });
    } catch (e) {
      alert(e);
    } finally {
      await getPayment({
        inputValue: inputValue,
        extra_data: {
          accountNumber: accountNumberValue,
          bankCode: bankCodeValue,
          description: descriptionValue,
          iban: ibanValue,
          id: idValue,
          nameSurname: fullNameValue,
          notes: addNotesValue,
          tcNum: "2312",
          wallet_id: walletIdValue,
        },
        service_id: Number(selectedPaymentMethod?.service_id),
      });
      handleModalStatus(false);
    }
  };

  const amount = useTypeSelector(
    (data) => data.accountInfoReducer.balance.balance.amount
   );
  const { amount_bonus } = useTypeSelector(
    (data) => data.accountInfoReducer.balance.balance
   );

  useEffect(() => {
    if (
      // Number(inputValue) + 1 <=
      //   Number(selectedPaymentMethod?.currencies[0].min) ||
      Number(inputValue) >= Number(selectedPaymentMethod?.currencies[0].max) ||
      Number(inputValue) === 0
    ) {
      setButtonSubmitDisabled(true);
    } else {
      setButtonSubmitDisabled(false);
    }
  }, [inputValue, selectedPaymentMethod]);

  useEffect(() => {
    async function getPayemntMethods() {
      const res = await AdminPanel.userDeposit("?filter[type]=2");
      setPaymentMethods(res.data);
      setSelectedPaymentMethod(res.data[0]);
    }
    getPayemntMethods();
  }, []);
  return (
    <>
      <div
        className="modal-overlay"
        style={{ display: isModalOpen ? "flex" : "none" }}
        // onClick={onClose}
      >
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <p>Do you want to delete the active bonus?</p>
          <button onClick={handlePayAfterBonus}>Yes</button>
          <button onClick={() => handleModalStatus(false)}>No</button>
        </div>
      </div>
      <div className="overlay-header">{t("admin.Withdraw")}</div>
      <div className="dep-w-info-bc" data-scroll-lock-scrollable="">
        <div className="m-block-nav-items-bc">
          {paymentMethods.map((item) => {
            return (
              <PayemntItem
                key={item.id}
                paymentInfo={item}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                setButtonSubmitDisabled={setButtonSubmitDisabled}
                setInputValue={setInputValue}
              />
            );
          })}
        </div>
      </div>
      <div className="payment-info-bc" data-scroll-lock-scrollable="">
        <div className="description-c-row-bc deposit">
          <div className="description-c-row-column-bc texts">
            <div className="description-c-row-c-title-bc ">
              <div className="description-c-r-c-t-column-bc">
                <span
                  className="description-title  ellipsis"
                  title="Payment name"
                >
                  {t("admin.Paymentname")}
                </span>
                <span className="description-value">
                  {selectedPaymentMethod?.name}
                </span>
              </div>
              <div className="description-c-r-c-t-column-bc">
                <span className="description-title  ellipsis" title="Fee">
                  {t("admin.Fee")}
                </span>
                <span className="description-value">
                  {/* {mockPaymentData[selectedPaymentMethod]?.Fee} */}
                </span>
              </div>
              <div className="description-c-r-c-t-column-bc">
                <span
                  className="description-title  ellipsis"
                  title="Process Time"
                >
                  {t("admin.ProcessTime")}
                </span>
                <span className="description-value">
                  {/* {mockPaymentData[selectedPaymentMethod]?.ProcessTime} */}
                </span>
              </div>
              <div className="description-c-r-c-t-column-bc">
                <span className="description-title  ellipsis" title="Min">
                  {t("admin.Min")}
                </span>
                <span className="description-value">
                  {selectedPaymentMethod?.currencies[0].min} ₺
                </span>
              </div>
              <div className="description-c-r-c-t-column-bc">
                <span className="description-title  ellipsis" title="Max">
                  {t("admin.Max")}
                </span>
                <span className="description-value">
                  {selectedPaymentMethod?.currencies[0].max} ₺
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="WithdrawAmount-All">
          <ul className="WithdrawAmount-Row">
            <li className="WithdrawAmount-Row-Title">
              {t("admin.WithdrawableAmount")}
            </li>
            <li className="WithdrawAmount-Row-Value">{(amount + amount_bonus).toFixed(2)} ₺</li>
          </ul>
          <ul className="WithdrawAmount-Row">
            <li className="WithdrawAmount-Row-Title">{t("admin.Balance")}</li>
            <li className="WithdrawAmount-Row-ValueBalance">0.00 ₺</li>
          </ul>
          <ul className="WithdrawAmount-Row">
            <li className="WithdrawAmount-Row-Title">
              {t("admin.Unplayedamount")}
            </li>
            <li className="WithdrawAmount-Row-Value">0.00 ₺</li>
          </ul>
        </div>

        <div className="deposit-method-description HedefHavale">
          {selectedPaymentMethod?.description}
        </div>
        <div className="withdraw-form-l-bc">
          <>
            <WitdhrowExtraData
              inputValue={inputValue}
              setInputValue={setInputValue}
              bankCodeValue={bankCodeValue}
              setBankCodeValue={setBankCodeValue}
              fullNameValue={fullNameValue}
              setFullNameValue={setFullNameValue}
              ibanValue={ibanValue}
              setIbanValue={setIbanValue}
              lastCharsValue={lastCharsValue}
              setLastCharsValue={setLastCharsValue}
              idValue={idValue}
              setIdValue={setIdValue}
              addNotesValue={addNotesValue}
              setAddNotesValue={setAddNotesValue}
              accountNumberValue={accountNumberValue}
              setAccountNumberValue={setAccountNumberValue}
              descriptionValue={descriptionValue}
              setDescriptionValue={setDescriptionValue}
              walletIdValue={walletIdValue}
              setWalletIdValue={setWalletIdValue}
              payment_name={selectedPaymentMethod?.payment_name}
              payment_aggregator_id={
                selectedPaymentMethod?.payment_aggregator_id
              }
              id={selectedPaymentMethod?.id}
            />
            <div className="u-i-p-c-footer-bc">
              <button
                className="btn a-color deposit "
                title="DEPOSIT"
                disabled={buttonSubmitDisabled}
                onClick={handleMoveToPay}
              >
                <span>{t("admin.dowithdraw")}</span>
              </button>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export { Withdraw };
