import React from "react";
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import "./ModalRegisterForm.scss";
import { InputAddress } from "./RegisterInputs/InputAddress/InputAddress";
import { InputAgreement } from "./RegisterInputs/InputAgreement/InputAgreement";
import { InputBirthDay } from "./RegisterInputs/InputBirthDay/InputBirthDay";
import { InputCountry } from "./RegisterInputs/InputCountry/InputCountry";
import { InputCurrency } from "./RegisterInputs/InputCurrency/InputCurrency";
import { InputEmail } from "./RegisterInputs/InputEmail/InputEmail";
import { InputGender } from "./RegisterInputs/InputGender/InputGender";
import { InputLastName } from "./RegisterInputs/InputLastName/InputLastName";
import { InputLoyaltycode } from "./RegisterInputs/InputLoyaltycode/InputLoyaltycode";
import { InputName } from "./RegisterInputs/InputName/InputName";
import { InputPassport } from "./RegisterInputs/InputPassport/InputPassport";
import { InputPassword } from "./RegisterInputs/InputPassword/InputPassword";
import { InputPasswordConfirm } from "./RegisterInputs/InputPasswordConfirm/InputPasswordConfirm";
import { InputPhoneNumber } from "./RegisterInputs/InputPhoneNumber/InputPhoneNumber";
import { InputPromocode } from "./RegisterInputs/InputPromocode/InputPromocode";
import { InputSity } from "./RegisterInputs/InputSity/InputSity";
import { InputUserName } from "./RegisterInputs/InputUserName/InputUserName";
import { userFormType } from "../ModalRegister";

interface IModalRegisterForm {
  register: UseFormRegister<userFormType>;
  watch: UseFormWatch<userFormType>;
  birthdayData: Date | null;
  setBirthdayData: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedGender: number;
  setSelectedGender: React.Dispatch<React.SetStateAction<number>>;
  selectedCountryShort: string;
  setSelectedCountryShort: React.Dispatch<React.SetStateAction<string>>;
  countryCode: number;
  setCountryCode: React.Dispatch<React.SetStateAction<number>>;
  currenctSelect: string;
  setCurrenctSelect: React.Dispatch<React.SetStateAction<string>>;
  errors: FieldErrors<userFormType>;
  errorsRequest: any;
}

const ModalRegisterForm = ({
  register,
  watch,
  birthdayData,
  setBirthdayData,
  selectedGender,
  setSelectedGender,
  selectedCountryShort,
  setSelectedCountryShort,
  countryCode,
  setCountryCode,
  currenctSelect,
  setCurrenctSelect,
  errors,
  errorsRequest,
}: IModalRegisterForm) => {
  return (
    <div className="entrance-form-content-section-bc">
      <InputName
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
      />
      <InputLastName
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
      />
      <InputUserName
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
      />
      <InputPassword
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
      />
      <InputPasswordConfirm
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
      />
      <InputBirthDay
        birthdayData={birthdayData}
        setBirthdayData={setBirthdayData}
        errorsRequest={errorsRequest}
      />
      <InputPassport
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
      />
      <InputEmail
        register={register}
        watch={watch}
        errors={errors}
        errorsRequest={errorsRequest}
      />
      <InputGender
        selectedGender={selectedGender}
        setSelectedGender={setSelectedGender}
        errorsRequest={errorsRequest}
      />
      <InputCountry
        selectedCountryShort={selectedCountryShort}
        setSelectedCountryShort={setSelectedCountryShort}
      />
      <InputSity
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
      />
      <InputAddress register={register} watch={watch} />
      <InputPhoneNumber
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        register={register}
        watch={watch}
      />
      <InputCurrency
        currenctSelect={currenctSelect}
        setCurrenctSelect={setCurrenctSelect}
      />
      {/* <InputLoyaltycode /> */}
      <InputPromocode register={register} watch={watch} />
      <InputAgreement register={register} />
    </div>
  );
};

export { ModalRegisterForm };
