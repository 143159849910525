import { t } from "i18next";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./BalanceInfo.scss";
import { useTypeSelector } from "hooks/useTypeSelector";

const BalanceInfo = () => {
 const amount = useTypeSelector(
  (data) => data.accountInfoReducer.balance.balance.amount
 );
 const { amount_bonus } = useTypeSelector(
  (data) => data.accountInfoReducer.balance.balance
 );
 return (
  <div className="u-i-p-amount-holder-bc">
   <div className="u-i-p-amounts-bc withdrawable">
    <div className="u-i-p-a-content-bc">
     <div className="total-balance-r-bc">
      <div className="u-i-p-a-user-balance">
       <span className="u-i-p-a-title-bc ellipsis">
        {t("admin.MainBalance")}
       </span>
       <b className="u-i-p-a-amount-bc">
        {amount.toFixed(2)}
       </b>
      </div>
     </div>
     <div className="u-i-p-a-buttons-bc">
      <Link
       className="u-i-p-a-deposit-bc ellipsis"
       to={{
        search: "?profile=open&account=balance&page=deposit",
       }}
      >
       <i className="bc-i-wallet" />
       <span className="ellipsis" title="Deposit">
        {t("admin.Deposit")}
       </span>
      </Link>
      <Link
       className="u-i-p-a-withdraw-bc ellipsis"
       to={{
        search: "?profile=open&account=balance&page=withdraw",
       }}
      >
       <i className="bc-i-withdraw" />
       <span className="ellipsis" title="Withdraw">
        {t("admin.Withdraw")}
       </span>
      </Link>
     </div>
    </div>
   </div>
   <div className="u-i-p-amounts-bc bonuses">
    <div className="u-i-p-a-content-bc">
     <span className="u-i-p-a-title-bc ellipsis">
      {t("admin.TotalBonusBalance")}
     </span>
     <span className="u-i-p-a-amount-bc">{amount_bonus.toFixed(2)}</span>
     <div className="bonus-info-section">
      <div>
       <span className="ellipsis">{t("admin.BonusBalance")}</span>
       <b>{amount_bonus.toFixed(2)}</b>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export { BalanceInfo };
