import React, { useEffect, useState } from "react";
import "./CasinoFreeSpins.scss";
import loader from "accets/images/gif/loader/loader.gif";
import { t } from "i18next";
import axios from "axios";
import { useTypeSelector } from "hooks/useTypeSelector";
import { GameItem } from "components/liveCasino/gamesList/components/GameItem/GameItem";
import { CasinoIconGameType } from "common/types/Casino/CasinoIconGameType";
import { FavoritCasinoResponceType } from "common/types/Casino/FavoritCasinoResponceType";
import { FavoritCasinoStateType } from "common/types/Casino/FavoritCasinoStateType";
import { CasinoFreespinGameType } from "common/types/Casino/CasinoFreespinGameType";

const CasinoFreeSpins = () => {
  const { active_bonus } = useTypeSelector(
    (data) => data.accountInfoReducer.balance
  );
  const [gamesList, setGamesList] = useState<CasinoFreespinGameType[]>();
  const [load, setLoad] = useState(true);
  const [freeSpinType, setFreeSpinType] = useState("new");
  const handleFreeSpintChange = (item: string) => {
    setFreeSpinType(item);
  };
  useEffect(() => {
    function getGames() {
      const token = localStorage.getItem("token");
      if (token) {
        const params = {
          games: active_bonus?.settings.freespin_games || "",
          providers: active_bonus?.settings.providers || "",
          categories: active_bonus?.settings.categories || "",
        };
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        axios
          .get("https://casino-api.services/user-dashboard/freespin_info", {
            params: params,
            headers: headers,
          })
          .then((response) => {
            setGamesList(response.data.games);
            setLoad(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setLoad(false);
          });
      }
    }
    getGames();
  }, []);
  // console.log(gamesList);
  return (
    <>
      <div className="overlay-header">Casino Free Spins</div>
      <div className="sportBonusWrapper-bc" data-scroll-lock-scrollable="">
        <div className="second-tabs-bc">
          <div
            className={`tab-bc selected-underline ${
              freeSpinType === "new" ? "active" : ""
            } `}
            title=""
            onClick={() => handleFreeSpintChange("new")}
          >
            {t("admin.New")}
          </div>
          <div
            className={`tab-bc selected-underline ${
              freeSpinType === "activated" ? "active" : ""
            } `}
            title=""
            onClick={() => handleFreeSpintChange("activated")}
          >
            {t("admin.Activated")}
          </div>
        </div>
        {load ? (
          <div className="bc-loader medium ">
            <div className="bc-loader-contain">
              <img src={loader} alt="" />
            </div>
          </div>
        ) : (
          <>
            {Array.isArray(gamesList) ? (
              <div className="games-horiz ">
                <div className="games-horiz-scroll">
                  {gamesList.map((item) => (
                    <GameItem gameItem={item} key={item.id} />
                  ))}
                </div>
              </div>
            ) : (
              <p className="empty-b-text-v-bc">
                {t("admin.Therearenobonusesforselectedtype")}
              </p>
            )}
          </>
        )}
      </div>
    </>
  );
};

export { CasinoFreeSpins };
