import React, { useEffect, useState } from "react";
import "./CasinoBonus.scss";
import loader from "accets/images/gif/loader/loader.gif";
import { t } from "i18next";

const CasinoBonus = () => {
  const [load, setLoad] = useState(false);
  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 3000);
  }, []);
  return (
    <>
      <div className="overlay-header">{t("admin.CasinoBonus")}</div>
      <div className="sportBonusWrapper" data-scroll-lock-scrollable="">
        {load ? (
          <div className="bc-loader medium ">
            <div className="bc-loader-contain">
              <img src={loader} alt="" />
            </div>
          </div>
        ) : (
          <p className="empty-b-text-v-bc">
            {t("admin.Therearenobonusesforselectedtype")}
          </p>
        )}
      </div>
    </>
  );
};

export { CasinoBonus };
