import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userBalanceType } from "common/types/AdminInfo/balanceType/userBalanceType";
import { UserInformation } from "common/types/AdminInfo/UserInformation";
import { BannersList } from "common/types/Banners/BannersList";

interface UserInfo {
  email: string;
  id: string;
}

interface StateUser {
  user: UserInformation;
  role: string;
  balance: userBalanceType;
  banners: BannersList[];
}

const initialState: StateUser = {
  user: {
    id: 15,
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    passport: "",
    country: "",
    state: "",
    city: undefined,
    address: "",
    zipcode: null,
    language: "",
    documents: null,
    currency: null,
    identification_number: null,
    gender: "",
    birthday: "",
    available_currencies: ["TRY", "USD", "EUR"],
    documents_verified: false,
    email_verified_at: null,
    phone_verified: null,
    api_user_id: 12653,
    google2fa_enable: 0,
    hide_nulled_balance: false,
    crypto_print: false,
  },
  role: "unauth",
  balance: {
    balance: {
      amount: 0,
      amount_bonus: 0,
      currency: "",
    },
    cryptoBalance: null,
    fiatBalance: null,
    active_bonus: null,
    init: false,
    pendingBonus: null,
    extra_data: {
      game_token: "",
      softswiss_freespin: false,
      crypto_payment: null,
    },
  },
  banners: [],
};

const accountInfoSlice = createSlice({
  name: "accountInfo",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<UserInformation>) => {
      state.user = action.payload;
    },
    setUserBalance: (state, action: PayloadAction<number>) => {
      state.balance = {
        ...state.balance,
        init: true,
        balance: {
          ...state.balance.balance,
          amount: action.payload,
        },
      };
    },
    setUserBalanceInfo: (state, action: PayloadAction<userBalanceType>) => {
      state.balance = action.payload;
    },
    setUserInit: (state) => {
      state.balance.init = true;
    },
    setBannersList: (state, action: PayloadAction<BannersList[]>) => {
      state.banners = action.payload;
    },
  },
});

export const {
  setAuth,
  setUserInfo,
  setUserBalance,
  setUserInit,
  setBannersList,
  setUserBalanceInfo,
} = accountInfoSlice.actions;
export default accountInfoSlice;
export type { UserInfo, StateUser };
export { initialState as initialStateBalance };
