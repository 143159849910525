import React, { useEffect, useState } from "react";

import { mockCouruselMainType } from "./mockCourusel/mockCouruselMain";

import "./Courusel.scss";
import { BananersEnum, BannersList } from "common/types/Banners/BannersList";
import { useTypeSelector } from "hooks/useTypeSelector";
import { Link } from "react-router-dom";

interface ICourusel {
 mockCourusel: BannersList[];
 filtredSelector: BananersEnum;
}

const Courusel = ({ mockCourusel, filtredSelector }: ICourusel) => {
 const bannerList = mockCourusel.filter(
  (item) => item.type === filtredSelector
 );
 const [currentIndex, setCurrentIndex] = useState(0);
 const [windowSize, setWindowSize] = useState(0);
 const next = () => {
  if (currentIndex < bannerList.length - 1) {
   setCurrentIndex((prevState) => prevState + 1);
  } else {
   setCurrentIndex(() => 0);
  }
 };

 const prev = () => {
  if (currentIndex > 0) {
   setCurrentIndex((prevState) => prevState - 1);
  } else {
   setCurrentIndex(() => bannerList.length - 1);
  }
 };
 useEffect(() => {
  const updatePageWidth = () => {
   setWindowSize(document.documentElement.offsetWidth);
  };

  updatePageWidth();

  window.addEventListener("resize", updatePageWidth);

  return () => {
   window.removeEventListener("resize", updatePageWidth);
  };
 }, []);
 const lang = useTypeSelector(
  (data) => data.settingsReducer.language.languageShort
 );
 return (
  <div
   className="hm-row-bc has-slider"
   style={{ gridTemplateColumns: "12fr", marginBottom: 20 }}
  >
   <div className="slider-bc  ">
    <div className="carousel">
     <div className="carousel-viewport">
      <div
       className="carousel-container"
       style={{
        transform: `translate3d(-${currentIndex * 100}%, 0px, 0px)`,
       }}
      >
       {bannerList.map((item) => {
        return (
         <Link
          className="carousel-slide "
          style={{ width: "100%" }}
          key={item.id}
          to={item.url ? `/${lang}/${item.url}` : "#"}
         >
          <div className="sdr-item-holder-bc">
           <div className="sdr-item-bc">
            <img
             style={{ maxHeight: windowSize * 0.4 }}
             src={item.desktop_image}
             loading="lazy"
             className="sdr-image-bc"
             alt=""
             title={item.title}
            />
           </div>
          </div>
         </Link>
        );
       })}
      </div>
     </div>
     <div className="carousel-count-arrow-container with-count">
      <i
       className="carousel-arrow-left bc-i-small-arrow-left "
       onClick={prev}
      />
      <div className="carousel-count-arrow">
       {currentIndex + 1} / {bannerList.length}
      </div>
      <i
       className="carousel-arrow-right bc-i-small-arrow-right "
       onClick={next}
      />
     </div>
    </div>
   </div>
  </div>
 );
};

export { Courusel };
