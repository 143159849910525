import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { useTypeSelector } from "hooks/useTypeSelector";
import { HeaderData } from "./headerData/HeaderData";
import "./NavHeader.scss";

interface INavHeader {
  navStatus: string;
}

const NavHeader = ({ navStatus }: INavHeader) => {
  const languageShort = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  return (
    <div className="nav-content-bc">
      <div className="nav-content-menu">
        <nav className="nav-menu-container header-navigation ">
          <ul className="nav-menu nav-menu-hide-items custom-header-container">
            {HeaderData.map((item) => (
              <li key={item.name} className="">
                <Link
                  className={`nav-menu-item ${
                    navStatus === item.status ? "active" : ""
                  } `}
                  target="_self"
                  to={
                    item.outSideLink
                      ? item.path
                      : `/${languageShort}${item.path}`
                  }
                >
                  {item.icon ? <i className={item.icon}></i> : null}
                  <span>{t(item.name)}</span>
                </Link>
              </li>
            ))}
          </ul>
          <ul className="nav-menu-other ">
            <li className="nav-menu-item">
              <i className="bc-i-apps"></i>
              {t("navHeader.More")}
              <ul className="nav-menu-sub custom-burger-nav">
                {/* <li className="">
                  <Link
                    className={`nav-menu-item ${
                      navStatus === "maltbahiscarc" ? "active" : ""
                    }`}
                    target="_self"
                    to={`/${languageShort}/wonderwheel`}
                  >
                    <i className="bc-i-wonderwheel"></i>
                    <span>{t("navHeader.MALTBAHISCARK")}</span>
                  </Link>
                </li> */}
                <li className="">
                  <Link
                    className={`nav-menu-item ${
                      navStatus === "jackpot" ? "active" : ""
                    }`}
                    target="_self"
                    to={`/${languageShort}/casino/jackpot`}
                  >
                    <span>{t("navHeader.Jackpot")}</span>
                  </Link>
                </li>
                {/* <li className="">
                  <Link
                    to="https://www.to-harmony.com"
                    target="_blank"
                    className="nav-menu-item"
                    rel="noreferrer"
                  >
                    <span>{t("navHeader.TOHARMONY")}</span>
                  </Link>
                </li> */}
                {/* <li className="">
                  <Link
                    className={`nav-menu-item ${
                      navStatus === "tournaments" ? "active" : ""
                    }`}
                    target="_self"
                    to={`/${languageShort}/casino/tournaments`}
                  >
                    <span>{t("navHeader.Tournaments")}</span>
                  </Link>
                </li> */}
                <li className="">
                  <Link
                    className={`nav-menu-item ${
                      navStatus === "promotions" ? "active" : ""
                    }`}
                    target="_self"
                    to={`/${languageShort}/promotions/all`}
                  >
                    <span>{t("navHeader.Promotions")}</span>
                  </Link>
                </li>
                <li className="">
                  <Link
                    className={`nav-menu-item ${
                      navStatus === "games" ? "active" : ""
                    }`}
                    target="_self"
                    to={`/${languageShort}/games`}
                  >
                    <span>{t("navHeader.Games")}</span>
                  </Link>
                </li>
                {/* <li className="">
                  <Link
                    className={`nav-menu-item ${
                      navStatus === "canitakvim" ? "active" : ""
                    }`}
                    target="_self"
                    to={`/${languageShort}/sports/live-calendar`}
                  >
                    <span>{t("navHeader.CANLITAKVIM")}</span>
                  </Link>
                </li> */}
                {/* <li className="">
                  <Link
                    to={`https://statistics.bcapps.org/#/${languageShort}`}
                    target="_self"
                    className="nav-menu-item"
                  >
                    <span>{t("navHeader.Statistics")}</span>
                  </Link>
                </li> */}
                {/* <li className="">
                  <Link
                    className={`nav-menu-item ${
                      navStatus === "bahisrurnuvalari" ? "active" : ""
                    }`}
                    target="_self"
                    to={`/${languageShort}/sports/tournaments`}
                  >
                    <i className="bc-i-tournament"></i>
                    <span>{t("navHeader.BAHISTURNUVALARI")}</span>
                  </Link>
                </li> */}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      {navStatus === "sport" || navStatus === "sport-tournaments" ? (
        <nav className="nav-menu-container header-navigation ">
          <ul className="nav-menu nav-menu-hide-items">
            <li className="">
              <Link
                className={`nav-menu-item ${
                  navStatus === "sport" ? "active" : ""
                }`}
                target="_self"
                to={`/${languageShort}/sports/pre-match/event-view`}
                aria-current="page"
              >
                <i className="bc-i-prematch" />
                <span>SPOR BAHİSLERİ</span>
              </Link>
            </li>
            <li className="">
              <Link
                className={`nav-menu-item ${
                  navStatus === "sport-tournaments" ? "active" : ""
                }`}
                target="_self"
                to={`/${languageShort}/sports/pre-match/tournaments`}
              >
                <i className="bc-i-tournament" />
                <span>Sport Tournaments</span>
              </Link>
            </li>
          </ul>
          <ul className="nav-menu-other hidden-global">
            <li className="nav-menu-item active">
              <i className="bc-i-apps" />
              More
              <ul className="nav-menu-sub" />
            </li>
          </ul>
        </nav>
      ) : null}
    </div>
  );
};

export { NavHeader };
