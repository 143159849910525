import { userDeposit } from "common/types/AdminInfo/userDeposit";
import { AdminPanel } from "services";

type getPaymentManualUrlType = {
  inputValue: string;
  selectedPaymentMethod?: userDeposit;
};

async function getPaymentManualUrl({
  inputValue,
  selectedPaymentMethod,
}: getPaymentManualUrlType) {
  try {
    await AdminPanel.userPayManual({
      data: {
        amount: Number(inputValue) * 100,
        currency: "TRY",
        payment_aggregator_id: selectedPaymentMethod?.payment_aggregator_id,
        service_id: Number(selectedPaymentMethod?.service_id) || 0,
      },
      params: "",
    });
    alert("Succesful");
  } catch (e) {
    alert(e);
  }
}

export { getPaymentManualUrl };
