import React from "react";
import { userDeposit } from "common/types/AdminInfo/userDeposit";
import "./PayemntItem.scss";

import mimipay from "./minipay_bank.png";
import mimipay1 from "./minipay_card.png";

interface IPayemntItem {
  paymentInfo: userDeposit | undefined;
  selectedPaymentMethod: userDeposit | undefined;
  setSelectedPaymentMethod?: React.Dispatch<
    React.SetStateAction<userDeposit | undefined>
  >;
  setButtonSubmitDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const PayemntItem = ({
  paymentInfo,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  setButtonSubmitDisabled,
  setInputValue,
}: IPayemntItem) => {
  const handleChangeSelectedPayment = (item: userDeposit | undefined) => {
    if (item) {
      setSelectedPaymentMethod && setSelectedPaymentMethod(item);
    }
    setInputValue("");
    setButtonSubmitDisabled(false);
  };
  return (
    <div
      className={`m-nav-items-list-item-bc ${
        selectedPaymentMethod?.id === paymentInfo?.id ? "active" : ""
      }`}
      onClick={() => handleChangeSelectedPayment(paymentInfo)}
    >
      <div className="nav-ico-w-row-bc">
        {paymentInfo?.logo ? (
          <img className="payment-logo" src={paymentInfo?.logo} alt="" />
        ) : null}

        <p>{paymentInfo?.name}</p>
      </div>
    </div>
  );
};

export { PayemntItem };
