import React from "react";
import { CasinoIconGameType } from "common/types/Casino/CasinoIconGameType";
import { GameItem } from "components/liveCasino/gamesList/components/GameItem/GameItem";
import "./SkillGames.scss";

interface ISkillGames {
  casinoGamesList: [] | CasinoIconGameType[];
}

const SkillGames = ({ casinoGamesList }: ISkillGames) => {
  return (
    <div className="skill-games-page-bc">
      {casinoGamesList &&
        casinoGamesList.map((item) => {
          return <GameItem key={item.name} gameItem={item} />;
        })}
    </div>
  );
};

export { SkillGames };
