import React, { useEffect, useRef, useState } from "react";
import { CasinoIconGameType } from "common/types/Casino/CasinoIconGameType";
import "./HorizontalList.scss";
import { HorizontalListItem } from "./HorizontalListUI/HorizontalListItem";

interface IHorizontalList {
  casinoGamesList: [] | CasinoIconGameType[];
}

const HorizontalList = ({ casinoGamesList }: IHorizontalList) => {
  const [widthComponent, setWidthComponent] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scroll, setScroll] = useState(0);
  const [scrollPos, setScrollPos] = useState(0);
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = entry.contentRect.width;
        setWidthComponent(width);
      }
    });

    if (componentRef.current) {
      resizeObserver.observe(componentRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScrolRight = () => {
    const widthDef = widthComponent - windowWidth;
    setScroll((prev) => prev + widthDef / 10);
    setScrollPos((prev) => prev + 1);
  };

  const handleScrolLeft = () => {
    const widthDef = widthComponent - windowWidth;
    setScroll((prev) => prev - widthDef / 10);
    setScrollPos((prev) => prev - 1);
  };

  return (
    <div className="horizontal-sl-list casino-horizontal-sl-list-bc horizontal-items-expanded scroll-start scroll-end ">
      {scrollPos !== 0 ? (
        <i
          className="horizontal-sl-arrow bc-i-small-arrow-left"
          onClick={handleScrolRight}
        />
      ) : null}

      <div
        ref={componentRef}
        className="horizontal-sl-wheel srcoll-custom custom-scroll"
        style={{ transform: `translateX(${scroll}px)` }}
      >
        {casinoGamesList &&
          casinoGamesList.map((item) => {
            return <HorizontalListItem itemInfo={item} key={item.name} />;
          })}
      </div>
      {scrollPos === -10 ? null : (
        <i
          className="horizontal-sl-arrow bc-i-small-arrow-right"
          onClick={handleScrolLeft}
        />
      )}
    </div>
  );
};

export { HorizontalList };
