import React, { useEffect, useState } from "react";
import { CasinoIconGameType } from "common/types/Casino/CasinoIconGameType";
import { HorizontalList } from "components/games/HorizontalList/HorizontalList";
import { SkillGames } from "components/games/skillGames/SkillGames";
import { Layout } from "components/layout";
import { CasinoInfo } from "services";

import "./Games.scss";

const Games = () => {
  const [panelOnep, setPanelOpen] = useState(true);
  const [betsLipOpen, setBetsLipOpen] = useState(false);
  const [casinoGamesList, setCasinoGamesList] = useState<
    CasinoIconGameType[] | []
  >([]);
  useEffect(() => {
    async function casinoGames() {
      const res = await CasinoInfo.games(
        `/6?current_page=1&limit=25&page=1&is_mobile=true`
      );
      setCasinoGamesList((prevState) => prevState.concat(res.data.data));
    }
    casinoGames();
  }, []);
  return (
    <Layout
      addClasses={`${
        betsLipOpen ? "betslip-Full" : "betslip-Hidden"
      } skill-games-page compact-footer page-full-size skill-games ${
        panelOnep ? "smart-panel-is-visible" : ""
      }`}
      panelOnep={panelOnep}
      setPanelOpen={setPanelOpen}
      navStatus="games"
      betsLipPage={""}
      betsLipOpen={betsLipOpen}
      setBetsLipOpen={setBetsLipOpen}
    >
      
        <div
          className="hm-row-bc has-slider"
          style={{ gridTemplateColumns: "12fr" }}
        />
        <HorizontalList
          casinoGamesList={casinoGamesList}
        />
        <SkillGames
          casinoGamesList={casinoGamesList}
        />
    </Layout>
  );
};

export { Games };
