import { useEffect } from "react";
import { CasinoGameType } from "common/types/Casino/CasinoGameType";
import { CasinoInfo, AdminPanel } from "services";
import { useTypeSelector } from "./useTypeSelector";

interface IusePlayersWindows {
  role: string;
  openGamesUrl: string;
  setOpenGames: React.Dispatch<React.SetStateAction<string[]>>;
  gameNamesUrl: string;
  setGameNames: React.Dispatch<React.SetStateAction<string[]>>;
  openGames: string[];
  gameNames: string[];
  language: string;
  casinoLink: string[];
  gamesSelected: CasinoGameType[];
  setGamesSelected: React.Dispatch<React.SetStateAction<CasinoGameType[]>>;
  setCasinoBanner: React.Dispatch<React.SetStateAction<string>>;
  setCasinoLink: React.Dispatch<React.SetStateAction<string[]>>;
}

const usePlayersWindows = ({
  role,
  openGamesUrl,
  setOpenGames,
  gameNamesUrl,
  setGameNames,
  openGames,
  gameNames,
  language,
  casinoLink,
  gamesSelected,
  setGamesSelected,
  setCasinoBanner,
  setCasinoLink,
}: IusePlayersWindows) => {
  const { balance } = useTypeSelector((data) => data.accountInfoReducer);
  // .extra_data.softswiss_freespin
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (openGamesUrl) {
      const openGamesArray = openGamesUrl.split(",");
      setOpenGames(openGamesArray);
    }

    if (gameNamesUrl) {
      const gameNamesArray = gameNamesUrl.split(",");
      setGameNames(gameNamesArray);
    }
  }, [openGamesUrl, gameNamesUrl]);

  useEffect(() => {
    async function searchPlaySecond() {
      try {
        const gameUrl = `/${openGames[0]}/${gameNames[0]}?currency=TRY&lang=${language}&platform=desktop&game_link=true`;
        const res = token
          ? await CasinoInfo.casinoPlay(gameUrl)
          : await CasinoInfo.casinoPlayDemo(gameUrl);

        if (
          balance.extra_data.softswiss_freespin &&
          balance.active_bonus?.settings.freespin_games &&
          res.data.has_freespins
        ) {
          const launchCode = res.data.launch_code;
          const freespinGames = balance.active_bonus.settings.freespin_games;

          if (freespinGames.some((game) => game.includes(launchCode))) {
            await CasinoInfo.casinoPlayFreespins(launchCode);
            setGamesSelected([res.data, gamesSelected[0]]);
            setCasinoBanner(res.data.background_banner);
            setCasinoLink([res.data.game_link, casinoLink[0]]);
          } else {
            setGamesSelected([res.data, gamesSelected[0]]);
            setCasinoBanner(res.data.background_banner);
            setCasinoLink([res.data.game_link, casinoLink[0]]);
          }
        } else {
          setGamesSelected([res.data, gamesSelected[0]]);
          setCasinoBanner(res.data.background_banner);
          setCasinoLink([res.data.game_link, casinoLink[0]]);
        }
      } catch {
        console.error("");
      }
    }
    // async function searchPlay() {
    //   try {
    //     if (token) {
    //       const res = await CasinoInfo.casinoPlay(
    //         `/${openGames[0]}/${gameNames[0]}?currency=TRY&lang=${language}&platform=desktop&game_link=true`
    //       );
    //       if (balance.extra_data.softswiss_freespin) {
    //         if (balance.active_bonus?.settings.freespin_games) {
    //           if (res.data.has_freespins) {
    //             for (
    //               let i = 0;
    //               i < balance.active_bonus?.settings?.freespin_games.length;
    //               i++
    //             ) {
    //               if (
    //                 balance.active_bonus?.settings?.freespin_games[i].includes(
    //                   res.data.launch_code
    //                 )
    //               ) {
    //                 await CasinoInfo.casinoPlayFreespins(res.data.launch_code);
    //                 setGamesSelected([res.data, gamesSelected[1]]);
    //                 setCasinoBanner(res.data.background_banner);
    //                 setCasinoLink([res.data.game_link, casinoLink[1]]);
    //               }
    //             }
    //           } else {
    //             setGamesSelected([res.data, gamesSelected[1]]);
    //             setCasinoBanner(res.data.background_banner);
    //             setCasinoLink([res.data.game_link, casinoLink[1]]);
    //           }
    //         }
    //       } else {
    //         setGamesSelected([res.data, gamesSelected[1]]);
    //         setCasinoBanner(res.data.background_banner);
    //         setCasinoLink([res.data.game_link, casinoLink[1]]);
    //       }
    //     } else {
    //       const res = await CasinoInfo.casinoPlayDemo(
    //         `/${openGames[0]}/${gameNames[0]}?currency=TRY&lang=${language}&platform=desktop&game_link=true`
    //       );
    //       setGamesSelected([res.data, gamesSelected[1]]);
    //       setCasinoBanner(res.data.background_banner);
    //       setCasinoLink([res.data.game_link, casinoLink[1]]);
    //     }
    //   } catch {
    //     console.error("");
    //   }
    // }
    async function searchPlay() {
      try {
        const gameUrl = `/${openGames[0]}/${gameNames[0]}?currency=TRY&lang=${language}&platform=desktop&game_link=true`;

        const res = token
          ? await CasinoInfo.casinoPlay(gameUrl)
          : await CasinoInfo.casinoPlayDemo(gameUrl);

        if (
          balance.extra_data.softswiss_freespin &&
          balance.active_bonus?.settings.freespin_games &&
          res.data.has_freespins
        ) {
          const launchCode = res.data.launch_code;
          const freespinGames = balance.active_bonus.settings.freespin_games;

          if (freespinGames.some((game) => game.includes(launchCode))) {
            await CasinoInfo.casinoPlayFreespins(launchCode);
            setGamesSelected([res.data, gamesSelected[1]]);
            setCasinoBanner(res.data.background_banner);
            setCasinoLink([res.data.game_link, casinoLink[1]]);
          } else {
            setGamesSelected([res.data, gamesSelected[1]]);
            setCasinoBanner(res.data.background_banner);
            setCasinoLink([res.data.game_link, casinoLink[1]]);
          }
        } else {
          setGamesSelected([res.data, gamesSelected[1]]);
          setCasinoBanner(res.data.background_banner);
          setCasinoLink([res.data.game_link, casinoLink[1]]);
        }
      } catch (error) {
        console.error(error);
      }
    }

    openGames[0] && searchPlay();
    openGames[1] && searchPlaySecond();
  }, [openGames, gameNames]);
};

export { usePlayersWindows };
