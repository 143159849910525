import React from "react";
import { useDispatch } from "react-redux";
import { useTypeSelector } from "hooks/useTypeSelector";
import { UserHeaderPlayer } from "./components/player/UserHeaderPlayer";
import { UserHeaderUnauth } from "./components/unauth/UserHeaderUnauth";
import "./UserHeader.scss";
import { setAuth } from "store/reducers/accountInfoReducer";


const UserHeader = () => {
  const { role } = useTypeSelector((data) => data.accountInfoReducer);
  const dispatch = useDispatch();
  const handleMockUnauth = () => {
    dispatch(setAuth("unauth"));
    localStorage.removeItem('token');
  };
  return (
    <div className="hdr-user-bc">
      {role === "unauth" ? (
        <UserHeaderUnauth
        />
      ) : (
        <UserHeaderPlayer handleMockUnauth={handleMockUnauth} />
      )}
    </div>
  );
};

export { UserHeader };
