import React, { useEffect, useState } from "react";
import { useTypeSelector } from "hooks/useTypeSelector";
import { BottomButton } from "./BottomButton/BottomButton";

import "./Header.scss";
import { MainHeader } from "./mainHeader/MainHeader";
import { NavHeader } from "./navHeader/NavHeader";
import { RedirectInfo } from "./redirectInfo/RedirectInfo";

interface iHeader {
 panelOnep: boolean;
 setPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
 navStatus: string;
 betsLipOpen?: boolean;
 setBetsLipOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header = ({
 panelOnep,
 setPanelOpen,
 navStatus,
 betsLipOpen,
 setBetsLipOpen,
}: iHeader) => {
 const [prevScrollY, setPrevScrollY] = useState(0);
 const [centerButtonVisivaly, setCenterButtonVisivaly] = useState(false);

 // const showRedirectIfno = useTypeSelector(
 //   (data) => data.settingsReducer.showRedirectInfo
 // );

 const showRedirectIfno = true;
 useEffect(() => {
  const handleScroll = () => {
   const currentScrollY = window.pageYOffset;

   if (currentScrollY < prevScrollY) {
    setCenterButtonVisivaly(false);
   } else {
    if (currentScrollY > 230) {
     setCenterButtonVisivaly(true);
     setTimeout(() => {
      setCenterButtonVisivaly(false);
     }, 5000);
    }
   }

   setPrevScrollY(currentScrollY);
  };

  window.addEventListener("scroll", handleScroll);

  return () => {
   window.removeEventListener("scroll", handleScroll);
  };
 }, [prevScrollY]);

 return (
  <div className="layout-header-holder-bc">
   <RedirectInfo />
   <header className="header-bc">
    <MainHeader
     panelOnep={panelOnep}
     setPanelOpen={setPanelOpen}
     betsLipOpen={betsLipOpen}
     setBetsLipOpen={setBetsLipOpen}
    />
    <NavHeader navStatus={navStatus} />
    {centerButtonVisivaly ? <BottomButton /> : null}
   </header>
  </div>
 );
};

export { Header };
