import { api } from "httpApi/ApiAuth/index";
import { AxiosResponse } from "axios";
import { CasinoGameType } from "common/types/Casino/CasinoGameType";
import { GameProvider } from "common/types/Casino/GameProvider";
import { login } from "common/types/Auth/login";
import { UserInformation } from "common/types/AdminInfo/UserInformation";
import { userDeposit } from "common/types/AdminInfo/userDeposit";
import { userPay, userPayBody } from "common/types/AdminInfo/userPay";
import { userBalanceType } from "common/types/AdminInfo/balanceType/userBalanceType";
import { userBets } from "common/types/AdminInfo/userBets";
import { register } from "common/types/Auth/register";
import { changePassword } from "common/types/Auth/changePassword";
import { FavoritCasinoResponceType } from "common/types/Casino/FavoritCasinoResponceType";
import { LastWinnersReguestType } from "common/types/Casino/LastWinnerType";
import { BannersList } from "common/types/Banners/BannersList";
import { apiTokenLess, apiUnauth } from "httpApi";
import { promotionsResponceType } from "common/types/Promotions/promotionsResponceType";
import { ContentType } from "common/types/Content/ContentType";
import { PageType } from "common/types/PageType/PageType";
import { SettingsResponceType } from "common/types/SettingsInfo/SettingsResponceType";
import { transactionsType } from "common/types/AdminInfo/transactionsType/transactionsType";

const token = process.env.REACT_APP_API_ORIGIN_TOKEN;

class AuthService {
  static async login({
    username,
    password,
  }: login): Promise<AxiosResponse<string>> {
    return apiUnauth.post<string>("api/login", { username, password });
  }
  static async register({
    address,
    affid,
    birthday,
    country,
    currency,
    cxd,
    email,
    firstname,
    gender,
    language,
    lastname,
    passport,
    password,
    password_confirmation,
    phone,
    promocode,
    state,
    username,
    zipcode,
  }: register): Promise<AxiosResponse<string>> {
    return apiUnauth.post<string>("api/register", {
      address,
      affid,
      birthday,
      country,
      currency,
      cxd,
      email,
      firstname,
      gender,
      language,
      lastname,
      passport,
      password,
      password_confirmation,
      phone,
      promocode,
      state,
      username,
      zipcode,
    });
  }
  static async changePassword({
    current_password,
    password,
    password_confirmation,
  }: changePassword): Promise<AxiosResponse<string>> {
    return apiUnauth.post<string>("/api/change-password", {
      current_password,
      password,
      password_confirmation,
    });
  }
}

class CasinoInfo {
  static async providers(
    searchParams?: string
  ): Promise<AxiosResponse<GameProvider>> {
    // const bearerToken = localStorage.getItem("token");
    // const headers = bearerToken
    //   ? { Authorization: `Bearer ${bearerToken}` }
    //   : undefined;
    return api.get<GameProvider>(`/providers${searchParams}`);
  }

  static async categories(searchParams: string): Promise<AxiosResponse<any>> {
    // const bearerToken = localStorage.getItem("token");
    // const headers = bearerToken
    //   ? { Authorization: `Bearer ${bearerToken}` }
    //   : undefined;
    return api.get<any>(`/categories-with-games${searchParams}`);
  }

  static async games(searchParams: string): Promise<AxiosResponse<any>> {
    // const bearerToken = localStorage.getItem("token");
    // const headers = bearerToken
    //   ? { Authorization: `Bearer ${bearerToken}` }
    //   : undefined;
    return api.get<any>(`/category-with-games${searchParams}`);
  }

  static async mainPageGames(): Promise<AxiosResponse<any>> {
    return api.get<any>(`/home-page-games-flat?is_all=1`);
  }

  static async searchGames(searchParams: string): Promise<AxiosResponse<any>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;
    return api.get<any>(`/search-games${searchParams}`);
  }
  static async setFavoritGame(data: {
    game_id: number;
    type: number;
  }): Promise<AxiosResponse<FavoritCasinoResponceType>> {
    return api.post<FavoritCasinoResponceType>(`api/add-to-favorite`, data);
  }
  static async favoritGames(): Promise<
    AxiosResponse<FavoritCasinoResponceType[]>
  > {
    return api.get<FavoritCasinoResponceType[]>(`/api/favorites-games`);
  }
  static async casinoPlay(
    searchParams: string
  ): Promise<AxiosResponse<CasinoGameType>> {
    return api.get<CasinoGameType>(`/casino${searchParams}`);
  }
  static async casinoPlayDemo(
    searchParams: string
  ): Promise<AxiosResponse<CasinoGameType>> {
    return apiTokenLess.get<CasinoGameType>(`/casino${searchParams}`);
  }
  static async casinoPlayFreespins(
    searchParams: string
  ): Promise<AxiosResponse<string>> {
    return api.get<string>(
      `/user-dashboard/freespin-request?launch_code=${searchParams}`
    );
  }
  static async lastWinners(): Promise<AxiosResponse<LastWinnersReguestType>> {
    return apiUnauth.post<LastWinnersReguestType>("/api/last-winners");
  }
  static async MainBanners(
    lang: string
  ): Promise<AxiosResponse<BannersList[]>> {
    return apiTokenLess.get<BannersList[]>(`/banners?lang=${lang}`);
  }
  static async sportbookStartSession(
    lang: string
  ): Promise<AxiosResponse<string>> {
    return api.get<string>(`/sportbook-start-session?lang=${lang}`);
  }
  static async promotions(
    promoId: string
  ): Promise<AxiosResponse<promotionsResponceType>> {
    return api.get<promotionsResponceType>(`/api/promotions/${promoId}`);
  }
  static async contact(lang: string): Promise<AxiosResponse<ContentType>> {
    return api.get<ContentType>(`/content?${lang}`);
  }
  static async page(id: string): Promise<AxiosResponse<PageType>> {
    return api.get<PageType>(`/api/pages/${id}`);
  }
}

class AdminPanel {
  static async userInfo(): Promise<AxiosResponse<UserInformation>> {
    return api.post<UserInformation>("/api/user");
  }

  static async userBalance(): Promise<AxiosResponse<userBalanceType>> {
    return api.get<userBalanceType>("/api/update-user-balance");
  }
  static async userBets(params: string): Promise<AxiosResponse<userBets>> {
    return api.get<userBets>(`/user-dashboard/get-all-bets${params}`);
  }
  static async userDeposit(
    params: string
  ): Promise<AxiosResponse<userDeposit[]>> {
    return api.get<userDeposit[]>(`/payment-methods${params}`);
  }
  static async userPay({
    data,
    params,
  }: {
    data: userPay;
    params: string;
  }): Promise<AxiosResponse<userPayBody>> {
    return api.post<userPayBody>(
      `/api/user-dashboard/set-deposit${params}`,
      data
    );
  }
  static async userPayManual({
    data,
    params,
  }: {
    data: userPay;
    params: string;
  }): Promise<AxiosResponse<userPayBody>> {
    return api.post<userPayBody>(
      `/api/user-dashboard/set-manual-deposit${params}`,
      data
    );
  }
  static async userWithdrow({
    data,
  }: {
    data: {
      amount: number;
      currency: string;
      extra_data: {
        accountNumber: string | null;
        bankCode: string | null;
        description: string | null;
        iban: string | null;
        id: string | null;
        nameSurname: string | null;
        notes: string | null;
        tcNum: string | null;
        wallet_id: string | null;
      };
      service_id: number;
    };
  }): Promise<AxiosResponse<userPayBody>> {
    return api.post<userPayBody>(`/api/user-dashboard/set-withdrawal`, data);
  }
  static async deleteBonus({
    data,
  }: {
    data: number;
  }): Promise<AxiosResponse<string>> {
    return api.post<string>(`/api/user-dashboard/delete-bonus/${data}`);
  }
  static async getDefaultSettings(): Promise<AxiosResponse<SettingsResponceType[]>> {
    return api.get<SettingsResponceType[]>(`/api/settings`);
  }
  static async transactions(
    params: string
  ): Promise<{ data: transactionsType[] }> {
    return api
      .get<{ data: transactionsType[] }>(`/user-dashboard/get-transactions${params}`)
      .then((response) => ({ data: response.data.data }));
  }
  static async withdrawals(
    params: string
  ): Promise<{ data: transactionsType[] }> {
    return api
      .get<{ data: transactionsType[] }>(`/user-dashboard/get-withdrawals${params}`)
      .then((response) => ({ data: response.data.data }));
  }
}

export { AuthService, CasinoInfo, AdminPanel };
