import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type modalsReducerType = {
  modalRegisterOpen: boolean;
  modalAuthOpen: boolean;
};

const initialState: modalsReducerType = {
  modalRegisterOpen: false,
  modalAuthOpen: false,
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setModalLogin: (state, action: PayloadAction<boolean>) => {
      state.modalAuthOpen = action.payload;
    },
    setModalRegister: (state, action: PayloadAction<boolean>) => {
      state.modalRegisterOpen = action.payload;
    },
  },
});

export const {
  setModalLogin,
  setModalRegister
} = modalsSlice.actions;
export default modalsSlice;
