import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import "./UserHeaderUnauth.scss";
import { setModalLogin, setModalRegister } from "store/reducers/modalsReducer";


const UserHeaderUnauth = () => {
  const dispatch = useDispatch();
  const handleOpenModalAuth = () => {
    dispatch(setModalLogin(true));
  };

  const handleOpenModalRegister = () => {
    dispatch(setModalRegister(true));
  };

  return (
    <>
      <button
        className="btn s-small sign-in "
        type="button"
        title="Sign in"
        onClick={handleOpenModalAuth}
      >
        <span>{t('admin.Signin')}</span>
      </button>
      <button
        className="btn s-small register "
        type="button"
        title="Register"
        onClick={handleOpenModalRegister}
      >
        <span>{t('admin.Register')}</span>
      </button>
    </>
  );
};

export { UserHeaderUnauth };
