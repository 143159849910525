import React from "react";

import "./ProductBannerMain.scss";
import { ProductBanner } from "components";
import { useTypeSelector } from "hooks/useTypeSelector";
import { BananersEnum } from "common/types/Banners/BannersList";
import { useNavigate } from "react-router-dom";

interface IProductBannerMain {
 enumType: BananersEnum;
 isDepositParams?: boolean;
}

const ProductBannerMain = ({
 enumType,
 isDepositParams,
}: IProductBannerMain) => {
 const banners = useTypeSelector((data) =>
  data.accountInfoReducer.banners.filter((item) => item.type === enumType)
 );
 const navigate = useNavigate();
 const { languageShort } = useTypeSelector(
  (data) => data.settingsReducer.language
 );
 const handleNavigate = (item: string | null) => {
  const searchParams = new URLSearchParams({
   profile: "open",
   account: "balance",
   page: "deposit",
  });
  if (isDepositParams) {
   navigate(`?${searchParams.toString()}`, { replace: true });
  } else {
   item && navigate(`/${languageShort}${item}`);
  }
 };
 return (
  <div className="hm-row-bc ">
   <ProductBanner>
    {banners.map((item) => {
     return (
      <div
       className="product-banner-info-bc product-banner-bc custom-main-banner"
       key={item.id}
       onClick={() => handleNavigate(item.url || null)}
      >
       <img
        className="product-banner-img-bc"
        src={item.desktop_image}
        alt={item.title}
       />
      </div>
     );
    })}
   </ProductBanner>
  </div>
 );
};

export { ProductBannerMain };
