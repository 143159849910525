import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { userDeposit } from "common/types/AdminInfo/userDeposit";
import { AdminPanel } from "services";
import "./Deposit.scss";
import { PayemntItem } from "./DepositUi/PayemntItem/PayemntItem";
import { getPaymentManualUrl } from "./utils";
import { getPaymentUrl } from "./utils/getPaymentUrl";

const Deposit = () => {
  const [paymentMethods, setPaymentMethods] = useState<userDeposit[] | []>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<userDeposit>();
  const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputFocused, setInputFocused] = useState(false);
  const [passportUnvalid, setPassportUnvalid] = useState(false);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleFocus = () => {
    setInputFocused(true);
  };

  const handleBlur = () => {
    if (inputValue.length === 0) {
      setInputFocused(false);
    } else {
      setInputFocused(true);
    }
    if (Number(inputValue) < Number(selectedPaymentMethod?.currencies[0].min)) {
      setButtonSubmitDisabled(true);
    }
    if (Number(inputValue) > Number(selectedPaymentMethod?.currencies[0].max)) {
      setButtonSubmitDisabled(false);
    }
  };

  const handleMoveToPay = () => {
    if (selectedPaymentMethod?.service_id === "77777") {
      getPaymentManualUrl({ inputValue, selectedPaymentMethod });
    } else {
      getPaymentUrl({ inputValue, setPassportUnvalid, selectedPaymentMethod });
    }
  };

  useEffect(() => {
    if (
      Number(inputValue) <= Number(selectedPaymentMethod?.currencies[0].max)
    ) {
      setButtonSubmitDisabled(false);
    } else {
      setButtonSubmitDisabled(true);
    }
  }, [buttonSubmitDisabled, inputValue, selectedPaymentMethod]);

  useEffect(() => {
    async function getPayemntMethods() {
      const res = await AdminPanel.userDeposit("?filter[type]=1");
      setPaymentMethods(res.data);
      setSelectedPaymentMethod(res.data[0]);
    }
    getPayemntMethods();
  }, []);

  return (
    <>
      <div className="overlay-header">{t("admin.Deposit")}</div>
      <div className="dep-w-info-bc" data-scroll-lock-scrollable="">
        <div className="m-block-nav-items-bc">
          {paymentMethods.map((item) => {
            return (
              <PayemntItem
                key={item.id}
                paymentInfo={item}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                setButtonSubmitDisabled={setButtonSubmitDisabled}
                setInputValue={setInputValue}
              />
            );
          })}
        </div>
      </div>
      <div className="payment-info-bc" data-scroll-lock-scrollable="">
        <div className="description-c-row-bc deposit">
          <div className="description-c-row-column-bc texts">
            <div className="description-c-row-c-title-bc ">
              <div className="description-c-r-c-t-column-bc">
                <span
                  className="description-title  ellipsis"
                  title="Payment name"
                >
                  {t("admin.Paymentname")}
                </span>
                <span className="description-value">
                  {selectedPaymentMethod?.name}
                </span>
              </div>
              <div className="description-c-r-c-t-column-bc">
                <span className="description-title  ellipsis" title="Fee">
                  {t("admin.Fee")}
                </span>
                <span className="description-value"></span>
              </div>
              <div className="description-c-r-c-t-column-bc">
                <span
                  className="description-title  ellipsis"
                  title="Process Time"
                >
                  {t("admin.ProcessTime")}
                </span>
                <span className="description-value">
                  {/* {mockPaymentData[selectedPaymentMethod]?.ProcessTime} */}
                </span>
              </div>
              <div className="description-c-r-c-t-column-bc">
                <span className="description-title  ellipsis" title="Min">
                  {t("admin.Min")}
                </span>
                <span className="description-value">
                  {selectedPaymentMethod?.currencies[0].min} ₺
                </span>
              </div>
              <div className="description-c-r-c-t-column-bc">
                <span className="description-title  ellipsis" title="Max">
                  {t("admin.Max")}
                </span>
                <span className="description-value">
                  {selectedPaymentMethod?.currencies[0].max} ₺
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="deposit-method-description HedefHavale">
          {selectedPaymentMethod?.description}
        </div>
        <div className="withdraw-form-l-bc">
          <div>
            <div className="u-i-p-control-item-holder-bc">
              <div
                className={`form-control-bc default ${
                  inputFocused ? "focused" : ""
                }`}
              >
                <label className="form-control-label-bc inputs">
                  <input
                    type="text"
                    inputMode="decimal"
                    className="form-control-input-bc"
                    name="value"
                    defaultValue=""
                    value={inputValue}
                    onChange={onChangeInput}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  <i className="form-control-input-stroke-bc" />
                  <span className="form-control-title-bc ellipsis">Value</span>
                </label>
              </div>
            </div>
            <div className="u-i-p-c-footer-bc">
              <button
                className="btn a-color deposit "
                title="DEPOSIT"
                disabled={buttonSubmitDisabled}
                onClick={handleMoveToPay}
              >
                <span>{t("admin.Deposit")}</span>
              </button>
            </div>
            {passportUnvalid && (
              <div className="unvalid-passport">
                {t("admin.Notavalidpassport")}!!!
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { Deposit };
