import React from "react";
import { useSearchParams } from "react-router-dom";
import { BalanceManagement } from "./AccountModals/BalanceManagement/BalanceManagement";
import { BetBuilderManagement } from "./AccountModals/BetBuilderManagement/BetBuilderManagement";
import { BetHistory } from "./AccountModals/BetHistory/BetHistory";
import { Bonuses } from "./AccountModals/Bonuses/Bonuses";
import { Messages } from "./AccountModals/Messages/Messages";
import { MyProfile } from "./AccountModals/MyProfile/MyProfile";
import "./SideBar.scss";
import { BalanceInfo } from "./SideBarUI/BalanceInfo/BalanceInfo";
import { LoyaltyPoint } from "./SideBarUI/LoyaltyPoint/LoyaltyPoint";
import { PromoCode } from "./SideBarUI/PromoCode/PromoCode";
import { UserInfo } from "./SideBarUI/UserInfo/UserInfo";
import { WarningVerification } from "./SideBarUI/WarningVerification/WarningVerification";

const SideBar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "";
  return (
    <div className="u-i-profile-page-container">
      <div className="u-i-profile-page-bc">
        <div
          className="u-i-profile-page-content"
          data-scroll-lock-scrollable=""
        >
          <UserInfo />
          <div className="separator-line-bc" />
          <BalanceInfo />
          <BalanceManagement page={page} />
          <BetBuilderManagement page={page} />
          <Bonuses page={page} />
          <MyProfile page={page} />
          <BetHistory page={page} />
          <Messages page={page} />
          <PromoCode />
        </div>
      </div>
    </div>
  );
};

export { SideBar };
