import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Ilanguage, setLanguage } from "store/reducers/settingsReducer";
import "./LanguageSelect.scss";

interface ILanguageSelect {
  language: Ilanguage;
  setSettings: React.Dispatch<React.SetStateAction<boolean>>;
}

const LanguageSelect = ({ language, setSettings }: ILanguageSelect) => {
  const { en } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);

  const handleItemSelect = (item: Ilanguage) => {
    dispatch(setLanguage(item));
    setSettings(false);
    const currentPath = location.pathname;
    const newPath = en
      ? currentPath.replace(en, item.languageShort)
      : currentPath;
    navigate(newPath);
  };

  const handleOpenModal = () => {
    setModalOpen((prev) => !prev);
  };
  const handleClosePanel = () => {
    setModalOpen(false);
  };

  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      handleClosePanel();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);
  return (
    <div
      className="form-control-bc select  filled"
      tabIndex={0}
      ref={wrapperRef}
    >
      <div
        className="form-control-label-bc form-control-select-bc inputs "
        onClick={handleOpenModal}
      >
        <i
          className={`select-icon-bc ftr-lang-bar-flag-bc flag-bc ${language.languageFlag}`}
        />
        <span className="ellipsis">{language.languageName}</span>
        <i className="form-control-icon-bc bc-i-small-arrow-down" />
        <i className="form-control-input-stroke-bc" />
        <span className="form-control-title-bc ellipsis">
          {t("admin.Language")}
        </span>
      </div>
      {modalOpen ? (
        <div className="multi-select-label-bc" data-scroll-lock-scrollable="">
          <label
            className={`checkbox-control-content-bc ${
              language.languageShort === "tr" ? "active" : ""
            } `}
            data-option-value="tur"
            onClick={() =>
              handleItemSelect({
                languageShort: "tr",
                languageName: "Türkçe",
                languageAbs: "TUR",
                languageFlag: "turkey",
              })
            }
          >
            <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc turkey" />
            <p
              className="checkbox-control-text-bc"
              style={{ pointerEvents: "none" }}
            >
              Türkçe
            </p>
          </label>
          <label
            className={`checkbox-control-content-bc ${
              language.languageShort === "en" ? "active" : ""
            } `}
            data-option-value="eng"
            onClick={() =>
              handleItemSelect({
                languageShort: "en",
                languageName: "English",
                languageAbs: "ENG",
                languageFlag: "unitedkingdom",
              })
            }
          >
            <i className="multi-select-icon-bc ftr-lang-bar-flag-bc flag-bc unitedkingdom" />
            <p
              className="checkbox-control-text-bc"
              style={{ pointerEvents: "none" }}
            >
              English
            </p>
          </label>
        </div>
      ) : null}
    </div>
  );
};

export { LanguageSelect };
