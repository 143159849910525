import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CasinoIconGameType } from "common/types/Casino/CasinoIconGameType";
import { useTypeSelector } from "hooks/useTypeSelector";
import { CasinoInfo } from "services";
import { GameItem } from "components/liveCasino/gamesList/components/GameItem/GameItem";

import "./MainGamesList.scss";

const MainGamesList = () => {
 const language = useTypeSelector(
  (data) => data.settingsReducer.language.languageShort
 );
 const [casinoGames, setCasinoGames] = useState<CasinoIconGameType[]>([]);
 const [mainGamesList, setMainGamesList] = useState<any>([]);
 useEffect(() => {
  async function getCasinoGames() {
   const res = await CasinoInfo.games(
    `/6?current_page=1&limit=5&page=2&is_mobile=true`
   );
   setCasinoGames(res.data.data);
  }
  async function getCasinoGamesMainPage() {
   const res = await CasinoInfo.mainPageGames();
   setMainGamesList(res.data);
  }
  getCasinoGames();
  getCasinoGamesMainPage();
 }, []);
 const allGames =
  useTypeSelector(
   (data) =>
    data.settingsReducer.content.skinStyles?.filter(
     (item: any) => item.key === "home-page-list"
    )[0].value
  ) || null;
 const uniqueArray =
  mainGamesList?.filter(
   (obj: any, index: any, self: any) =>
    index === self?.findIndex((t: any) => t.id === obj.id)
  ) || null;

 const chekIdList = JSON.parse(allGames)?.row_3 || "";
 const filteredObjects =
  uniqueArray?.filter((obj: any) => chekIdList?.includes(obj.id.toString())) ||
  null;
 return (
  <div className="hm-row-bc ">
   <div className="page-view-more">
    <h3 className="view-title-more ellipsis">{t("navHeader.Games")}</h3>
    <Link className="view-see-more" to={`/${language}/games`}>
     {t("navHeader.More")}
     <i className="view-arrow-more bc-i-small-arrow-right"></i>
    </Link>
   </div>
   <div className="games-horiz ">
    <div className="games-horiz-scroll" data-scroll-lock-scrollable="">
     {allGames
      ? filteredObjects.map((obj: any) => {
         return <GameItem key={obj.id} gameItem={obj} />;
        })
      : casinoGames.map((item) => {
         return <GameItem key={item.id} gameItem={item} />;
        })}
    </div>
   </div>
  </div>
 );
};

export { MainGamesList };
